import React, { Fragment } from "react";
// import p1_300x151 from "../assets/p1-300x151.jpg";
// import p2_300x151 from "../assets/p2-300x151.jpg";
// import p3_300x151 from "../assets/p3-300x151.jpg";
// import p4_300x151 from "../assets/p4-300x151.jpg";
// import p5_300x151 from "../assets/p5-300x151.jpg";
// import p6_300x151 from "../assets/p6-300x151.jpg";
export default function ourprojects() {
  return (
    <Fragment>
      <div>
        <main id="site-content">
          <article
            className="post-9 page type-page status-publish hentry"
            id="post-9"
          >
            <div className="post-inner thin ">
              <div className="entry-content">
                <section className="greenproject">
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <h2 className="mb-5 text-center"></h2>
                        <div className="projectdet">
                          <h3>Konya Selçuklu Solar Power Plant</h3>
                          <div className="installcap">
                            <label>Install Capacity:</label>
                            <span>4.4 mW</span>
                          </div>
                          {/*installcap*/}
                          <div className="plantdetout">
                            <div className="plantdet">
                              <label>Plant</label>Konya Selçuklu Solar Power
                              Plant
                            </div>
                            {/*plantdet*/}
                            <div className="plantdet">
                              <label>Location</label>Selçuklu / Konya / Turkey
                            </div>
                            {/*plantdet*/}
                            <div className="plantdet">
                              <label>License Number</label>80010264-265-266-267
                            </div>
                            {/*plantdet*/}
                            <div className="plantdet">
                              <label>Parcel area </label>112,825 m2{" "}
                            </div>
                            {/*plantdet*/}
                            {/* <div className="plantdet">
                              <label>Capacity </label>4,400 kW
                            </div> */}
                            {/*plantdet*/}
                            <div className="plantdet">
                              <label>Number of employess at the plant </label>6
                            </div>
                            {/*plantdet*/}
                            <div className="plantdet">
                              <label>Expected Revenue (Monthly) </label>$ 72,000
                            </div>
                            {/*plantdet*/}
                            <div className="plantdet">
                              <label>Operating expenses (Monthly) </label>$
                              30,000
                            </div>
                            {/*plantdet*/}
                            <div className="plantdet">
                              <label>Revenue to be airdropped (Monthy) </label>$
                              42,000
                            </div>
                            {/*plantdet*/}
                          </div>
                          {/*plantdetout*/}
                        </div>
                        {/*projectdet*/}
                      </div>
                      {/*col-md-12*/}
                    </div>
                    {/*row*/}
                    <div className="row projectdetrow">
                      {/*col-md-4*/}
                      <div className="col-md-12">
                        {" "}
                        <iframe
                          width="100%"
                          height="500px"
                          src="https://www.youtube.com/embed/X_N3In1ilx8?controls=0&amp;start=1"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowfullscreen
                        ></iframe>
                      </div>

                      {/*col-md-4*/}
                    </div>
                  </div>
                  {/*container*/}
                </section>
              </div>
              {/* .entry-content */}
            </div>
            {/* .post-inner */}
            <div className="section-inner"></div>
            {/* .section-inner */}
          </article>
          {/* .post */}
        </main>
        {/* #site-content */}
      </div>
    </Fragment>
  );
}
