import React, { useRef, useState } from "react";
import eth from "../assets/webp/eth.webp";
import tw from "../assets/webp/twticonimg.webp";
import contactIcon from "../assets/webp/contacticon.webp";
import telegram from "../assets/webp/telegramiconimg.webp";
import telegram1 from "../assets/telegramiconimg1.png";
import facebook from "../assets/webp/facebook.webp";
// import discord from "../assets/webp/discord.webp";
import mediumiconimg from "../assets/webp/mediumiconimg.webp";
import linkedin from "../assets/webp/linkedin.webp";
import instagram from "../assets/webp/instagram.webp";
import { useDispatch } from "react-redux";
import { subscribe } from "../redux/siteContentSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { Alert } from "@mui/material";
import { withNamespaces } from "react-i18next";

function Footer({ t }) {
  const subscribeEmail = useRef();
  const dispatch = useDispatch();
  const [subsErr, setSubsErr] = useState("");
  const onSubscribe = (e) => {
    e.preventDefault();
    if (subscribeEmail.current.value === "") {
      setSubsErr({ message: "Empty Input", type: "error" });
      return;
    }
    dispatch(subscribe(subscribeEmail.current.value))
      .then(unwrapResult)
      .then((result) => {
        console.log(result);
        setSubsErr({ message: "Success", type: "success" });
      })
      .catch((err) => {
        console.log(err);
        setSubsErr({ message: err.message, type: "error" });
      });
  };
  return (
    <div>
      <section className="signup">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h2>{t("footer.translate1")}</h2>
              <p>{t("footer.translate2")}</p>
              <div className="emaillist" id="es_form_f1-n1">
                <form className="es_subscription_form es_shortcode_form ">
                  <div className="es-field-wrap">
                    <label>
                      <input
                        onChange={(e) => {
                          setSubsErr("");
                        }}
                        className="es_required_field es_txt_email ig_es_form_field_email"
                        type="email"
                        name="esfpx_email"
                        placeholder="Enter your e-mail"
                        required="required"
                        ref={subscribeEmail}
                      />
                    </label>
                  </div>
                  <input
                    onClick={onSubscribe}
                    type="submit"
                    value={"Sign Up"}
                  />
                </form>
                {subsErr !== "" && (
                  <Alert style={{ width: "50%" }} severity={subsErr.type}>
                    {subsErr.message}
                  </Alert>
                )}

                <span
                  className="es_subscription_message "
                  id="es_subscription_message_634d561dd186f"
                />
              </div>
              <div className="followus">
                <h3>{t("footer.translate3")}</h3>
                <ul className="socmedia">
                  <li>
                    <a href="mailto:info@energreen.io">
                      <img src={contactIcon} />
                      <br />
                      <br />
                    </a>
                  </li>
                </ul>
                <h3>{t("footer.translate4")}</h3>
                <ul className="socmedia">
                  <li>
                    <a
                      href="https://www.linkedin.com/company/energreenio/"
                      target="_blank"
                    >
                      <img src={linkedin} />
                    </a>
                  </li>
                  <li>
                    <a href="https://t.me/energreenglobal" target="_blank">
                      <img src={telegram} />
                    </a>
                  </li>

                  <li>
                    <a href="https://twitter.com/energreenio" target="_blank">
                      <img src={tw} />
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.instagram.com/energreenio/"
                      target="_blank"
                    >
                      <img src={instagram} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/EnerGreenio"
                      target="_blank"
                    >
                      <img src={facebook} />
                    </a>
                  </li>
                  {/* 
                  <li>
                    <a href="https://discord.gg/dxRf3mDgZT" target="_blank">
                      <img src={discord} />
                    </a>
                  </li>
                   */}
                  <li>
                    <a href="https://medium.com/@energreen" target="_blank">
                      <img src={mediumiconimg} />
                    </a>
                  </li>
                  {/* <li>
                    <a href="https://t.me/energreen" target="_blank">
                      <img src={telegram1} />
                    </a>
                  </li>  */}
                </ul>
                <br />
                <a
                  className="greenbtn"
                  href="https://telegra.ph/EnerGreen-FAQ-05-12-2"
                  target="_blank"
                >
                  faq
                </a>
              </div>
            </div>
          </div>
          {/*row*/}
        </div>
        {/*container*/}
      </section>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-6"></div>
            {/*col-md-6*/}
            <div className="col-md-6">
              <div className="poweredby">
                powered by Ethereum
                <img src={eth} />
              </div>
            </div>
            {/*col-md-6*/}
          </div>
          {/*row*/}
        </div>
        {/*container*/}
      </footer>
    </div>
  );
}

export default withNamespaces()(Footer);
