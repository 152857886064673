import axios from "axios";
import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import env from "react-dotenv";
import { GetJWT } from "../components/GetJWT";
import { claimABI } from "../components/Util";

export default function Claim() {
  const [claims, setClaims] = useState(null);
  const [claimed, setClaimed] = useState({});
  const [loginErr, setLoginErr] = useState("");
  const [processingClaimTier, setProcessingClaimTier] = useState(null);

  const getClaimInfo = async function () {
    try {
      const response = await axios.get(
        env.CLAIM_API_URL + "claims/" + window.account,
        {
          headers: { Authorization: GetJWT() },
        }
      );
      // console.log("getClaimInfo", response.data);
      // {"private1":{"contractAddress":"0xB1F8C1eC669f34d06Ac0a808f012082c30B7F9c2","claimJson":{"recipient":"0x588f06836CDc73B8ada835f81cFBaC89148E71FF","claimLimit":"308756043956044000000","claimStartTimestamp":1708560000,"signature":"0xdd9e1ca51c6c42ddf5574bd3f82b5dddb858a69a55e63c42497adb4387067f580db4bbaed3eff77bcac45d95b9ba6f9bb79b729f84e8bcf9aefdb07f58333f4b1c"},"unlockedClaim":308.756043956044,"totalClaimAmount":2508.642857142856,"nextClaimCount":12,"nextClaimTimestamp":1711065600,"nextClaimAmount":183.323901098901},"private2":null,"public":null}
      for (var x of response.data.items) {
        if (x.contractAddress) {
          const claimContract = new ethers.Contract(
            x.contractAddress,
            claimABI(),
            window.provider
          );
          const contract = claimContract.connect(window.signer);
          let claimedAmount = await contract.claimed(window.account);
          claimed[x.tier] = parseFloat(ethers.utils.formatEther(claimedAmount));
        } else {
          claimed[x.tier] = 0;
        }
      }
      setClaims(response.data);
      setLoginErr("");
      // setAmount(response.data.data.amount);
      // return response.data;
    } catch (error) {
      console.error(error);
      console.log(error.response.status);
      if (error.response.status === 401) {
        setLoginErr("Please login!");
      } else {
        setLoginErr(error);
      }
    }
  };
  useEffect(() => {
    setTimeout(() => {
      getClaimInfo();
    }, 1000);
  }, []);

  function convertTimestampToDate(timestamp) {
    if (timestamp == 0)
      return "";
    return (new Date(timestamp * 1000)).toUTCString();
  }

  async function claim(x) {
    console.log("claim", x);
    setProcessingClaimTier(x.tier);
    try {
      const contract = new ethers.Contract(x.contractAddress, claimABI(), window.provider);
      let tx = await contract.connect(window.signer).claimTokens(x.claimJson.recipient, x.claimJson.claimLimit,
                                                                 x.claimJson.claimStartTimestamp, x.claimJson.signature);
      await tx.wait();
      // TODO: claimed popup
    } catch (e) {
      console.error(e);
    }
    await getClaimInfo();
    setProcessingClaimTier(null);
  }

  const tierNameToSaleName = {
    "private1": "Private Sale 1",
    "private2": "Private Sale 2",
    "public": "Public Sale",
  }

  // NOT: bos claim gizlenmek isteniyorsa x.contractAddress == "" gibi bir kontrol eklenebilir
  return (
    <>
      <div className="claim-container">
        {loginErr === "" ? (
          claims?.items.map(x=>
              <>
                  <div className="data">
                  <br/>
                  <h2>{tierNameToSaleName[x.tier]} : {x.totalClaimAmount.toFixed(2)} EGRN</h2><br/>
                  <span>unlocked: {x.unlockedClaim.toFixed(2)} EGRN</span><br/>
                  <span>claimed: {claimed[x.tier].toFixed(2)} EGRN</span><br/>
                  <span>next claim amount: {x.nextClaimAmount.toFixed(2)} EGRN</span><br/>
                  {x.nextClaimTimestamp ? (<><span>next claim date: {convertTimestampToDate(x.nextClaimTimestamp)}</span><br/></>) : <></>}

                  {processingClaimTier==x.tier ? <span>processing claim...</span> : <></>}
                  <br/>
                  {(x.unlockedClaim-claimed[x.tier]).toFixed(2) !== "0.00" ?
                   <button disabled={processingClaimTier!==null} onClick={() => claim(x)} className="greenbtn mb-3 mt-3">
                     claim: {(x.unlockedClaim-claimed[x.tier]).toFixed(2)} EGRN
                   </button>
                  : <></>}
                </div>
            </>)
        ) : (
          <div className="data">{loginErr}</div>
        )
      }
      </div>
      <div className="mb-5"></div>
    </>
  );
}
