import axios from "axios";
import env from "react-dotenv";
import { GetJWT } from "./GetJWT";

export const updateWallet = async function(wallet, api) {
  try {
    const response = await axios.post(
      env.API_URL + "account/updatewallet",
      {
        wallet: wallet,
      },
      {
        headers: { Authorization: GetJWT() },
      }
    );
    console.log("updateWallet", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    //alert("wallet update error");
    throw error.response.data.error;
  }
};
export const connectWallet = async () => {
  if (typeof window.ethereum !== "undefined") {
    // console.log("MetaMask is installed!");
  }
  if (typeof window.ethereum !== "undefined") {
    try {
      const address = await window.ethereum.enable(); //connect Metamask
      const obj = {
        connectedStatus: true,
        status: "",
        address: address,
      };

      updateWallet(obj.address[0]);
      return obj;
    } catch (error) {
      return {
        connectedStatus: false,
        status: "Connect to Metamask using the button on the top right.",
      };
    }
  } else {
    return {
      connectedStatus: false,
      status:
        "You must install Metamask into your browser: https://metamask.io/download.html",
    };
  }
};
