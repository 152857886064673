import React, { Fragment } from "react";
import chart from "../assets/revize-pie.png";
export default function tokenomics() {
  return (
    <Fragment>
      <div>
        <main id="site-content">
          <article
            className="post-11 page type-page status-publish hentry"
            id="post-11"
          >
            <div className="post-inner thin">
              <div className="entry-content">
                <section id="tokenomics" className="token token-inner">
                  <div className="container">
                    <div className="row">
                      <div className="col text-center">
                        <h2 className="mb-5 border-bottom-0">Tokenomics</h2>
                        <div className="tokendet">
                          <label>Token Name</label>Energreen
                        </div>
                        <div className="tokendet">
                          <label>Token Ticker</label>$EGRN
                        </div>
                        <div className="tokendet">
                          <label>Total Token Supply</label>200,000,000
                        </div>
                        <div className="tokendet">
                          <label>Initial Supply at TGE</label>5,505,787
                        </div>
                        <div className="tokendet">
                          <label>Initial Market CAP</label>$ 2,750,394
                        </div>
                        <div className="tokendet">
                          <label>Dilluted Market CAP</label>$ 100,000,000 ($
                          0.50)
                        </div>
                        <p className="text-center mt-5 mb-0 w-600 text-uppercase">
                          ENERGREEN Tokens Are Created on ETHEREUM Network
                        </p>
                      </div>
                    </div>
                    <p>{/*row*/}</p>
                    <div className="row chartrow">
                      <div className="col-md-12 text-center mb-3 pb-5">
                        <div className="chartimgsec">
                          <img
                            src={chart}
                            alt="img"
                            className="alignnone size-medium wp-image-332"
                          />
                        </div>
                      </div>
                      <p>{/*col-md-12*/}</p>
                      <div className="col-md-8 m-auto">
                        <div className="privatesale ">
                          <label>Private Sale Round 1</label>
                          <p />
                          <div className="psdata">
                            <span>Share of the total supply</span> : <p />
                            <p>0.35%</p>
                          </div>
                          <div className="psdata">
                            <span>Lock Period </span> : <p />
                            <p>
                              5% is released at TGE.
                              <br />
                              The remaning is locked for 9 months.
                            </p>
                          </div>
                          <div className="psdata">
                            <span>Vesting </span> : <p />
                            <p>7.5% per month, starting month 10.</p>
                          </div>
                        </div>
                        <p>{/*privatesale*/}</p>
                        <div className="privatesale mt-5">
                          <label>Private Sale Round 2</label>
                          <p />
                          <div className="psdata">
                            <span>Share of the total supply</span> :<p />
                            <p>0.40%</p>
                          </div>
                          <div className="psdata">
                            <span>Lock Period</span> :<p />
                            <p>
                              5% is released at TGE.
                              <br />
                              The remaning is locked for 8 months.
                            </p>
                          </div>
                          <div className="psdata">
                            <span>Vesting</span> :<p />
                            <p>7.5% per month, starting month 9.</p>
                          </div>
                        </div>
                        <p>{/*privatesale*/}</p>
                        <div className="privatesale mt-5">
                          <label>Public Sale IDO</label>
                          <p />
                          <div className="psdata">
                            <span>Share of the total supply</span> :<p />
                            <p>0.50%</p>
                          </div>
                          <div className="psdata">
                            <span>Lock Period</span> :<p />
                            <p>
                              8% is released at TGE.
                              <br />
                              The remaning is locked for a month.
                            </p>
                          </div>
                          <div className="psdata">
                            <span>Vesting</span> :<p />
                            <p>2% per week, starting month 2.</p>
                          </div>
                        </div>
                        <p>{/*privatesale*/}</p>
                        <div className="privatesale mt-5">
                          <label>Team</label>
                          <p />
                          <div className="psdata">
                            <span>Share of the total supply</span> :<p />
                            <p>10%</p>
                          </div>
                          <div className="psdata">
                            <span>Lock Period</span> :<p />
                            <p>12 months</p>
                          </div>
                          <div className="psdata">
                            <span>Vesting</span> :<p />
                            <p>
                              Linearly released in 96 months,
                              <br />
                              starting month 13.
                            </p>
                          </div>
                        </div>
                        <p>{/*privatesale*/}</p>
                        <div className="privatesale mt-5">
                          <label>Advisors</label>
                          <p />
                          <div className="psdata">
                            <span>Share of the total supply</span> :<p />
                            <p>3.25%</p>
                          </div>
                          <div className="psdata">
                            <span>Lock Period</span> :<p />
                            <p>12 months</p>
                          </div>
                          <div className="psdata">
                            <span>Vesting</span> :<p />
                            <p>
                              Linearly released in 48 months,
                              <br />
                              starting month 13.
                            </p>
                          </div>
                        </div>
                        <p>{/*privatesale*/}</p>
                        <div className="privatesale mt-5">
                          <label>Stake</label>
                          <p />
                          <div className="psdata">
                            <span>Share of the total supply</span> :<p />
                            <p>30%</p>
                          </div>
                          <div className="psdata">
                            <span>Vesting</span> :<p />
                            <p>
                              Loyalty for price stability <br></br>
                              First 6 months 2*% then fixed APR 12% <br></br>
                              Released according to staked amounts
                            </p>
                          </div>
                        </div>
                        <p>{/*privatesale*/}</p>
                        <div className="privatesale mt-5">
                          <label>Community &amp; Marketing</label>
                          <p />
                          <div className="psdata">
                            <span>Share of the total supply</span> :<p />
                            <p>16.5%</p>
                          </div>
                          <div className="psdata">
                            <span>Vesting</span> :<p />
                            <p>
                              0.75% released per month and partially
                              <br />
                              used based on the marketing plan.
                            </p>
                          </div>
                        </div>
                        <p>{/*privatesale*/}</p>
                        <div className="privatesale mt-5">
                          <label>Initial Liquidity</label>
                          <p />
                          <div className="psdata">
                            <span>Share of the total supply</span> :<p />
                            <p>1.50%</p>
                          </div>
                          <div className="psdata">
                            <span>Vesting</span> :<p />
                            <p>100% released at TGE.</p>
                          </div>
                        </div>
                        <p>{/*privatesale*/}</p>
                        <div className="privatesale mt-5">
                          <label>Reserve</label>
                          <p />
                          <div className="psdata">
                            <span>Share of the total supply</span> :<p />
                            <p>37.5%</p>
                          </div>
                          <div className="psdata">
                            <span>Lock Period</span> :<p />
                            <p>12 months</p>
                          </div>
                          <div className="psdata">
                            <span>Vesting</span> :<p />
                            <p>0.5% released per month starting <br></br>month 13 and kept in the reserve <br></br>for new investments.</p>
                          </div>
                        </div>
                        <p>{/*privatesale*/}</p>
                      </div>
                      <p>{/*col-md-12*/}</p>
                    </div>
                    <p>{/*row*/}</p>
                  </div>
                  <p>{/*container*/}</p>
                </section>
              </div>
              {/* .entry-content */}
            </div>
            {/* .post-inner */}
            <div className="section-inner"></div>
            {/* .section-inner */}
          </article>
          {/* .post */}
        </main>
      </div>
    </Fragment>
  );
}
