import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./App.css";
import "./fonts/AustenBold.ttf";
import { Provider } from "react-redux";
import { store } from "./store";
import i18n from './assets/i18n';

i18n.init();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider >
);
