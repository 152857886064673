import React, { useState, useEffect } from "react";
import { cookie } from "./GetJWT";
function AutoLogout({ totalSeconds = 3600 }) {
  const storedSeconds = localStorage.getItem("seconds");
  const [seconds, setSeconds] = useState(
    storedSeconds !== null ? storedSeconds : totalSeconds
  );
  useEffect(() => {
    if (storedSeconds) {
      setSeconds(Number(storedSeconds));
    }
  }, []);
  useEffect(() => {
    if (seconds === "done") {
      cookie.remove("token_energreen");
      localStorage.removeItem("userConfig");
      window.location.pathname = "/auth";
    }
    localStorage.setItem("seconds", seconds);
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);
    if (seconds <= 0) {
      localStorage.removeItem("seconds");
      setSeconds("done");
    }
    return () => clearInterval(interval);
  }, [seconds]);

  return null;
}
export default AutoLogout;
