import { bottom } from "@popperjs/core";
import { createPortal } from "react-dom";
import { withNamespaces } from "react-i18next";

const MODAL_STYLES = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  backgroundColor: "#0B1A1C",
  padding: "50px",
  zIndex: 1000,
  borderRadius: "10px",
  minHeight: 0,
};
const OVERLAY_STYLES = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0,.7)",
  zIndex: 1000,
};
const CLOSE_STYLES = {
  position: "absolute",
  top: 0,
  right: "10px",
  cursor: "pointer",
  fontSize: "30px",
};
const BUY_STYLES = {
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    rowGap: "10px",
  },
  input: {
    outline: "none",
    width: "100%",
    borderRadius: "10px",
  },
  button: {
    cursor: "pointer",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
  },
};
function BoxInfoModal({ open, children, onClose, data, t }) {
  if (!open) return null;
  console.log(data);
  return createPortal(
    <>
      <div style={OVERLAY_STYLES} />
      <div style={MODAL_STYLES} className="evergreen_modal">
        <div
          onClick={() => {
            onClose();
          }}
          style={CLOSE_STYLES}
        >
          x
        </div>
        <div className="engpoints paragraph" style={{ rowGap: "10px" }}>
          <div className="modal-top">
            <img src={data[0]} alt="icon" />
            <h3 style={{ margin: 0, marginTop: 20, fontSize: "32px" }}>
              {t(data[2])}
            </h3>
          </div>

          <div>
            <p className="modal-paragraph">{t(data[1])}</p>
          </div>
        </div>
      </div>
      {children}
    </>,
    document.getElementById("modal")
  );
}

export default withNamespaces()(BoxInfoModal);
