async function tryToSwitchChain({
  chainId,
  chainName,
  nativeCurrency,
  rpcUrls,
  blockExplorerUrls,
}) {
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId }],
    });
  } catch (err) {
    if (err.code == 4902) {
      await (window).ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId,
            chainName,
            nativeCurrency,
            rpcUrls,
            blockExplorerUrls,
          },
        ],
      });
    }
  }
}

export async function changeNetwork(newChainId) {
  if (newChainId == '0x38') {
    await tryToSwitchChain({
      chainId: '0x38',
      chainName: 'Binance Smart Chain',
      nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
      },
      rpcUrls: ['https://bsc-dataseed1.binance.org'],
      blockExplorerUrls: ['https://bscscan.com'],
    });

    return;
  } else if (newChainId == '0x1') {
    await tryToSwitchChain({
      chainId: '0x1',
    });
    return;
  } 
  else {
    await tryToSwitchChain({
      chainId: '0xaa36a7',
      chainName: 'Sepolia',
      nativeCurrency: {
        name: 'SepoliaETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: ['https://endpoints.omniatech.io/v1/eth/sepolia/public'],
      blockExplorerUrls: ['https://sepolia.etherscan.io'],
    });
    return;
  }
}

// await (window as any).ethereum.request({ -- Polygon
//   method: 'wallet_addEthereumChain',
//   params: [
//     {
//       chainName: 'Polygon Mainnet',
//       chainId: '0x89',
//       nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
//       rpcUrls: ['https://polygon.llamarpc.com'],
//       blockExplorerUrls: ['https://polygonscan.com/'],
//     },
//   ],
// });

