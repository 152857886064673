import { Backdrop, CircularProgress } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";

import video from "../assets/7cc.mp4";
import idoDesktopEn from "../assets/popup-listing-en.jpg";
import idoMobilEn from "../assets/popup-listing-en.jpg";
import idoDesktopTr from "../assets/popup-listing-tr.jpg";
import idoMobilTr from "../assets/popup-listing-tr.jpg";
import bitciturkiye from "../assets/bitci-turkiye.svg";
import uniswap from "../assets/uniswap.png";

import tick from "../assets/tickimg.png";
import star from "../assets/starimg.png";
import stake from "../assets/stakeimg.png";
import llicon from "../assets/llicon.png";
import prooficon from "../assets/prooficon.png";
import ins from "../assets/insuredicon.png";
import custicon from "../assets/custicon.png";
import project1 from "../assets/webp/our-projects-banner.webp";
import youtube from "../assets/youtube.svg";
import ecosystem from "../assets/webp/CHART.webp";
import trecosystem from "../assets/webp/tr_ecosystem.webp";
import chart from "../assets/webp/revize-pie.webp";
import trchart from "../assets/webp/tr_token2.webp";
import general from "../assets/webp/general-manager.webp";
import person from "../assets/abdullah-ozsahin.jpg";
import ModalVideo from "react-modal-video";

import home_user_pic from "../assets/webp/home_user_pic.webp";
import team3 from "../assets/webp/team3.webp";
import team5 from "../assets/webp/team5.webp";
import team6 from "../assets/team6.jpeg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import HomeGridBox from "../components/HomeGridBox";
import VideoModal from "../components/modals/VideoModal";
import Modal from "@mui/material/Modal";
import { withNamespaces } from "react-i18next";
import i18n from "../assets/i18n";

function Index({ t }) {
  useEffect(() => {
    document.body.classList.add("home-page");
    return () => {
      document.body.classList.remove("home-page");
    };
  }, []);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [isOpen, setOpen] = useState(false);
  const [idoModal, setIdoModal] = React.useState(false);
  const [btnModal, setBtnModal] = React.useState(false);
  const { content } = useSelector((store) => store.siteContent);
  const [siteContent, setSiteContent] = useState([]);
  useEffect(() => {
    if (content.length === 0) return;
    setSiteContent(content);
  }, [content]);
  useEffect(() => {
    if (siteContent.length === 0) return;
    const a = siteContent.find(
      (x) => x.key === "home_banner_title_green"
    ).value;
  }, [siteContent]);
  function createMarkup(html) {
    return { __html: html };
  }
  
  /*
  useEffect(() => {
    setIdoModal(true);
  }, []);
  */
  const handleClose = () => setIdoModal(false);
  /*
  useEffect(() => {
    setBtnModal(true);
  }, []);
  */
  const handleBtnClose = () => setBtnModal(false);
  return (
    <>
      <div className="warning">
        <div className="container">
          <div className="content">
            <div className="desc">
              <h1>{t("warning.translate1")}</h1>
              <h1 className="p">
                <p>0xDB8d6D3AC21e4efE367</p>
                <p>5BBB18514010AC9C5558F</p>
              </h1>
            </div>
            <h1>{t("warning.translate2")}</h1>
          </div>
        </div>
      </div>
      <VideoModal
        open={isOpen}
        onClose={() => {
          setOpen(false);
        }}
      />
      {siteContent.length > 0 ? (
        <div>
          <Modal
            open={idoModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="ido-modal"
          >
            <div className="modal-body"></div>
          </Modal>
          <Modal
            open={btnModal}
            onClose={handleBtnClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="btn-modal"
          >
            <div className="modal-body">
              <div className="content">
                <a href=" https://www.bitci.com.tr/exchange/advanced/EGRN_USDT" target="_blank">
                  <img src={bitciturkiye} />
                </a>
                <a target="_blank" href="http://www.dextools.io/app/en/ether/pair-explorer/0x74166d418e2e496a7fd4b21c112bbaa807783460">
                  <img src={uniswap} />
                </a>
              </div>
            </div>
          </Modal>
          <div>
            <Modal
              open={idoModal}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="ido-modal"
            >
              <div className="modal-body">
                <button className="close-btn" onClick={handleClose}>
                  X
                </button>
                <div className="content text-center">
                  <h1>{t("warning.translate1")}</h1>
                  <h1 className="p">
                    <p>0xDB8d6D3AC21e4efE367</p>
                    <p>5BBB18514010AC9C5558F</p>
                  </h1>
                  <h1>{t("warning.translate2")}</h1>
                </div>
                <div className="desktop">
                  <img
                    src={i18n.language === "en" ? idoDesktopEn : idoDesktopTr}
                    alt=""
                  />
                </div>
                <div className="mobil">
                  <img
                    src={i18n.language === "en" ? idoMobilEn : idoMobilTr}
                    alt=""
                  />
                </div>
              </div>
            </Modal>
          </div>
          {/*--- Home Section One Starts ----------*/}
          <section className="bannersec">
            <video
              autoplay="autoplay"
              loop="true"
              muted
              defaultmuted
              playsInline={true}
              id="myVideo"
            >
              <source src={video} type="video/mp4" />
            </video>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-12 slide-anim-2">
                  <h1>
                    <label className="theworlds">
                      {t("homebanner.translate1")}
                    </label>
                    {t("homebanner.translate2")}
                  </h1>
                  <ul className="slide-anim">
                    <li>{t("homebanner.translate3")}</li>
                    <li>{t("homebanner.translate4")}</li>
                    <li>{t("homebanner.translate5")}</li>
                    <li>{t("homebanner.translate6")}</li>
                    <li>{t("homebanner.translate7")}</li>
                  </ul>
                </div>
              </div>

              {/*row*/}
            </div>
            {/*container*/}
            {/* <a href="#energreen" className="arrdown">
              <i className="fa fa-angle-double-down" />
            </a> */}
          </section>
          {/*--- Home Section One Ends ----------*/}
          {/*--- Home Section Two Starts ----------*/}
          <section className="whategreen row mb-5" id="energreen">
            <div className="container">
              <h2 className="mb-5 text-center">{t("whatis.translate2")}</h2>
              <div className="energreen-grid-boxes col-xs-12">
                {/* 1 */}
                <HomeGridBox
                  icon={tick}
                  title={"whatis.translate3"}
                  textData={"whatis.translate4"}
                />
                {/* 2 */}
                <HomeGridBox
                  icon={star}
                  title={"whatis.translate5"}
                  textData={"whatis.translate6"}
                />
                {/* 3 */}
                <HomeGridBox
                  icon={stake}
                  title={"whatis.translate7"}
                  textData={"whatis.translate8"}
                />
                {/* 4 */}
                <HomeGridBox
                  icon={llicon}
                  title={"whatis.translate9"}
                  textData={"whatis.translate10"}
                />
                {/* 5 */}
                <HomeGridBox
                  icon={prooficon}
                  title={"whatis.translate11"}
                  textData={"whatis.translate12"}
                />
                {/* 6 */}
                <HomeGridBox
                  icon={ins}
                  title={"whatis.translate13"}
                  textData={"whatis.translate14"}
                />
                {/* 7 */}
                <HomeGridBox
                  icon={custicon}
                  title={"whatis.translate15"}
                  textData={"whatis.translate16"}
                />
                {/* 8 */}
                <HomeGridBox
                  icon={ins}
                  title={"whatis.translate17"}
                  textData={"whatis.translate18"}
                />

                {/* 9 */}
                <HomeGridBox
                  icon={ins}
                  title={"whatis.translate19"}
                  textData={"whatis.translate20"}
                />

                {/* 10 */}
                <HomeGridBox
                  icon={ins}
                  title={"whatis.translate21"}
                  textData={"whatis.translate22"}
                />
              </div>
            </div>

            {/*container*/}
          </section>
          <section className="projects" id="ourprojects">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h2 className="mb-5">{t("projects.translate19")}</h2>
                </div>
              </div>
              {/*row*/}
              <div class="row projectrow">
                <div class="col-md-12">
                  <h2 class="text-center"></h2>
                  <div class="projectdet">
                    <h3>{t("projects.translate1")}</h3>
                    <div class="installcap">
                      <label>{t("projects.translate2")}</label>
                      <span>4.4 mW</span>
                    </div>
                    <div class="plantdetout">
                      <div class="plantdet">
                        <label>{t("projects.translate3")}</label>
                        {t("projects.translate4")}
                      </div>
                      <div class="plantdet">
                        <label>{t("projects.translate5")}</label>
                        {t("projects.translate6")}
                      </div>
                      <div class="plantdet">
                        <label>{t("projects.translate7")}</label>
                        {t("projects.translate8")}
                      </div>
                      <div class="plantdet">
                        <label>{t("projects.translate9")} </label>
                        {t("projects.translate10")} &#13217;
                      </div>
                      <div class="plantdet">
                        <label>{t("projects.translate11")} </label>
                        {t("projects.translate12")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row projectrow">
                <div className="col-md-12">
                  <div
                    className="projectin"
                    style={{
                      position: "relative",
                      border: "6px solid",
                      borderRadius: "30px",
                      color: "#59cea6",
                    }}
                  >
                    <img
                      style={{
                        position: "absolute",
                        right: "10px",
                        bottom: "10px",
                        cursor: "pointer",
                      }}
                      src={youtube}
                      className="abs"
                      alt="icon"
                      onClick={(e) => {
                        setOpen(true);
                        console.log("test");
                      }}
                    />

                    <img src={project1} />
                  </div>
                  <br />
                  <p className="mb-4 w-100 " style={{ textAlign: "center" }}>
                    <strong>{t("projects.translate20")}</strong>
                  </p>
                </div>

                {/*col-md-4*/}
                <div className="col-md-12">
                  <p className="text-center mt-4 mb-0">
                    {t("projects.translate21")}
                    <Link to="/our-projects">
                      {" "}
                      {t("projects.translate22")}
                    </Link>{" "}
                    {t("projects.translate23")}
                  </p>
                </div>
                {/*col-md-12*/}
              </div>
              {/*row*/}
            </div>
            {/*container*/}
          </section>
          {/*--- Home Section Four Ends ----------*/}
          {/*--- Home Section Five Starts ----------*/}
          <section className="ecosystem" id="ecosystem">
            <div className="container">
              <div className="row">
                <div className="col text-center">
                  <h2 className="mb-5" style={{ marginBottom: "-3rem" }}>
                    {t("ecosystem.translate1")}
                  </h2>
                  <img src={i18n.language === "tr" ? trecosystem : ecosystem} />
                </div>
              </div>
            </div>
          </section>
          {/*--- Home Section Five Ends ----------*/}
          {/*--- Home Section Six Starts ----------*/}
          <section className="token" id="tokenomics">
            <div className="container">
              <div className="row">
                <div className="col text-center">
                  <h2 className="mb-5">{t("tokenomics.translate1")}</h2>
                  <br />
                  <br />
                  <br />
                  <div
                    style={{
                      border: "3px solid",

                      display: "inline-block",
                      padding: "16px",
                      color: "#59cea6",
                      textShadow:
                        "0 0 0px #fff, 0 0 0px #59cea6, 0 0 0px #59cea6, 0 0 0px #59cea6, 0 0 14px #59cea6, 0 0 0px #59cea6, 0 0 1px #59cea6, 0 0 0px #59cea6",
                    }}
                  >
                    <div className="border-1" style={{}}>
                      <div className="tokendet">
                        <label>{t("tokenomics.translate2")}</label>Energreen
                      </div>
                      <div className="tokendet">
                        <label>{t("tokenomics.translate3")}</label>$EGRN
                      </div>
                      <div className="tokendet">
                        <label>{t("tokenomics.translate4")}</label>
                        {t("tokenomics.translate5")}
                      </div>
                      <div className="tokendet">
                        <label>{t("tokenomics.translate6")}</label>
                        {t("tokenomics.translate7")}
                      </div>
                      <div className="tokendet">
                        <label>{t("tokenomics.translate8")}</label>
                        {t("tokenomics.translate9")}
                      </div>
                      {/* <div className="tokendet">
                        <label>Dilluted Market CAP</label>$100,000,000($0.50)
                      </div> */}
                      <p className="text-center mt-5 mb-0 w-600 text-uppercase">
                        {t("tokenomics.translate10")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/*row*/}
              <div className="row chartrow">
                <div className="col-md-12 text-center">
                  <div className="chartimgsec">
                    <img src={i18n.language === "tr" ? trchart : chart} />
                  </div>
                </div>
                {/*col-md-12*/}
              </div>
              {/*row*/}
              <div className="row">
                <div className="col-md-3 col-xs-6">
                  <div className="salediv">
                    <h4>
                      {t("sale.box1.translate1")}
                      <br />
                      {t("sale.box1.translate2")}
                    </h4>
                    <div className="saleprice">
                      <label>{t("sale.box1.translate3")}</label>
                      {t("sale.box1.translate4")}
                    </div>
                    {/*saleprice*/}
                    <p>{t("sale.box1.translate5")}</p>
                    <div className="soldout">{t("sale.box1.translate6")}</div>
                  </div>
                  {/*salediv*/}
                </div>
                {/*col-md-3 col-xs-6*/}
                <div className="col-md-3 col-xs-6">
                  <div className="salediv">
                    <h4>
                      {t("sale.box2.translate1")}
                      <br />
                      {t("sale.box2.translate2")}
                    </h4>
                    <div className="saleprice">
                      <label>{t("sale.box2.translate3")}</label>
                      {t("sale.box2.translate4")}
                    </div>
                    {/*saleprice*/}
                    <p>{t("sale.box2.translate5")}</p>
                    <div className="soldout">{t("sale.box2.translate6")}</div>
                  </div>
                  {/*salediv*/}
                </div>
                <div className="col-md-3 col-xs-6">
                  <div className="salediv">
                    <h4>{t("sale.box3.translate1")}</h4>
                    <div className="saleprice">
                      <label>{t("sale.box3.translate2")}</label>
                      {t("sale.box3.translate3")}
                    </div>
                    {/*saleprice*/}
                    <p>{t("sale.box3.translate4")}</p>
                    <div className="soldout">{t("sale.box2.translate6")}</div>
                  </div>
                  {/*salediv*/}
                </div>
                {/*col-md-3 col-xs-6*/}
                <div className="col-md-3 col-xs-6 selamm">
                  <div className="salediv">
                    <h4>{t("sale.box4.translate1")}</h4>
                    <div className="saleprice">
                      <label>{t("sale.box4.translate2")}</label>
                      {t("sale.box4.translate3")}
                    </div>
                    {/*saleprice*/}
                    <p>
                      {t("sale.box4.translate4")}
                    </p>
                    <div className="soldout">{t("sale.listed")}</div>
                  </div>
                  {/*salediv*/}
                </div>
                {/*col-md-3 col-xs-6*/}
              </div>
              {/*row*/}
            </div>
            {/*container*/}
          </section>
          {/*--- Home Section Six Ends ----------*/}
          {/*--- Home Section Seven Starts ----------*/}
          <section
            className="roadmap"
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginBottom: "-90px",
            }}
          >
            <h2>{t("roadmap.translate1")}</h2>
          </section>
          <section className="roadmap">
            <div className="timeline">
              <div className="t-container right">
                <div className="date">{t("roadmap.2021.translate1")}</div>
                <div className="content">
                  <p>
                    <li>{t("roadmap.2021.translate2")}</li>
                    <li>{t("roadmap.2021.translate3")}</li>
                    <li>{t("roadmap.2021.translate4")}</li>
                    <li>{t("roadmap.2021.translate5")}</li>
                  </p>
                </div>
              </div>
              <div className="t-container left">
                <div className="date">{t("roadmap.2022H1.translate1")}</div>
                <div className="content">
                  <p>
                    <li>{t("roadmap.2022H1.translate2")}</li>
                    <li>{t("roadmap.2022H1.translate3")}</li>
                    <li>{t("roadmap.2022H1.translate4")}</li>
                    <li>{t("roadmap.2022H1.translate5")}</li>
                    <li>{t("roadmap.2022H1.translate6")}</li>
                    <li>{t("roadmap.2022H1.translate7")}</li>
                    <li>{t("roadmap.2022H1.translate8")}</li>
                    <li>{t("roadmap.2022H1.translate9")}</li>
                  </p>
                </div>
              </div>
              <div className="t-container right">
                <div className="date">{t("roadmap.2022Q4.translate1")}</div>
                <div className="content">
                  <p>
                    <li>{t("roadmap.2022Q4.translate2")}</li>
                    <li>{t("roadmap.2022Q4.translate3")}</li>
                    <li>{t("roadmap.2022Q4.translate4")}</li>
                    <li>{t("roadmap.2022Q4.translate5")}</li>
                  </p>
                </div>
              </div>
              <div className="t-container left">
                <div className="date">{t("roadmap.2023Q1.translate1")}</div>
                <div className="content">
                  <p>
                    <li>{t("roadmap.2023Q1.translate2")}</li>
                  </p>
                </div>
              </div>
              <div className="t-container right">
                <div className="date">{t("roadmap.2023Q2.translate1")}</div>
                <div className="content">
                  <p>
                    <li>{t("roadmap.2023Q2.translate2")}</li>
                    <li>{t("roadmap.2023Q2.translate3")}</li>
                    <li>{t("roadmap.2023Q2.translate4")}</li>
                    <li>{t("roadmap.2023Q2.translate5")}</li>
                  </p>
                </div>
              </div>
              <div className="t-container left">
              <div className="date">{t("roadmap.2023Q3.translate1")}</div>
              <div className="content">
                <p>
                  <li>{t("roadmap.2023Q3.translate2")}</li>
                </p>
              </div>
              </div>
              <div className="t-container right">
                <div className="date">{t("roadmap.2023Q4.translate1")}</div>
                <div className="content">
                  <p>
                  <li>{t("roadmap.2023Q4.translate2")}</li>
                  <li>{t("roadmap.2023Q4.translate3")}</li>
                  </p>
                </div>
              </div>
              <div className="t-container left">
                <div className="date">{t("roadmap.2024Q1.translate1")}</div>
                <div className="content">
                  <p>
                  <li>{t("roadmap.2024Q1.translate2")}</li>
                  <li>{t("roadmap.2024Q1.translate3")}</li>
                  </p>
                </div>
              </div>
            </div>
          </section>
          {/*--- Home Section Seven Ends ----------*/}
          {/*--- Home Section Eight Starts ----------*/}
          <section className="team">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h2 className="mb-5">{t("team.translate1")}</h2>
                  <br />
                  <br />
                  <br />
                  <p>{t("team.translate2")}</p>
                  <p />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-4 col-xs-6">
                  <div className="team1">
                    <a
                      href=" https://www.linkedin.com/in/tarkan-ozballar-99873159/"
                      target="_blank"
                    >
                      <img src={general} />
                    </a>
                    <p>
                      TARKAN OZBALLAR
                      <br />
                      CEO
                    </p>
                  </div>
                </div>

                <div className="col-md-4 col-xs-6">
                  <div className="team1">
                    <a
                      href="https://www.linkedin.com/in/o%C4%9Fuzhan-yilmaz-4b1249aa/"
                      target="_blank"
                    >
                      <img src={home_user_pic} />
                    </a>
                    <p>
                      OGUZHAN YILMAZ
                      <br /> Corp. Com. Manager
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-xs-6">
                  <div className="team1">
                    <a
                      href="https://www.linkedin.com/in/ozsahinabdullah/"
                      target="_blank"
                    >
                      <img src={person} alt="icon" />
                    </a>
                    <p>
                      ABDULLAH OZSAHIN
                      <br /> Lawyer
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-xs-6">
                  <div className="team1">
                    <a
                      href="https://www.linkedin.com/in/an%C4%B1l-%C3%B6zyildiz-b7501012b/"
                      target="_blank"
                    >
                      <img src={team5} alt="icon" />
                    </a>
                    <p>
                      ANIL OZYILDIZ
                      <br /> Engineer
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-xs-6">
                  <div className="team1">
                    <a href="http://bileskedenetim.com.tr/" target="_blank">
                      <img src={team6} alt="icon" />
                    </a>
                    <p>FINANCE</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  );
}

export default withNamespaces()(Index);
