import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { ethers, utils } from "ethers";
import React, { useEffect, useState } from "react";
import { setBalanceData } from "../../redux/siteContentSlice";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { saleABI, ERC20ABI } from "../Util";
const saleAdress = "0xcb8987420fFD8291538e287d22C25f3A5E5D3678";
const usdtAdress = "0x1701fBdc898cEf07a3ebcF5f875726CBD47E387E";
const MODAL_STYLES = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  backgroundColor: "#0B1A1C",
  padding: "50px",
  zIndex: 1000,
  borderRadius: "10px",
};
const OVERLAY_STYLES = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0,.7)",
  zIndex: 1000,
};
const CLOSE_STYLES = {
  position: "absolute",
  top: 0,
  right: "10px",
  cursor: "pointer",
  fontSize: "30px",
};
const ABS_STYLES = {
  position: "absolute",
  bottom: "5px",
  right: "10px",
  fontSize: "10px",
  cursor: "default",
  userSelect: "none",
  letterSpacing: "1px",
};
const BUY_STYLES = {
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    rowGap: "10px",
  },
  input: {
    outline: "none",
    width: "100%",
    borderRadius: "10px",
  },
  button: {
    cursor: "pointer",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
  },
};
export default function BuyEngreenModal({
  open,
  children,
  onClose,
  setLoader,
}) {
  const { balance } = useSelector((store) => store.siteContent);
  const [usdt, setUsdt] = useState(0.4);
  const [egrn, setEgrn] = useState(1);
  let amountVal = usdt + "000000000000000000";
  const dispatch = useDispatch();
  if (!open) return null;

  const buyTokens = async () => {
    setLoader(true);

    try {
      // copy claim page
      const saleContract = new ethers.Contract(
        saleAdress,
        saleABI(),
        window.provider
      );
      const saleContractWithSigner = saleContract.connect(window.signer);
      const buyInUsers = await saleContractWithSigner.buyInUsers(
        window.account
      );
      // console.log(buyInUsers.toString(), "buyın");
      //
      const currencyContract = new ethers.Contract(
        usdtAdress,
        ERC20ABI(),
        window.provider
      );
      // console.log(window.account);
      const currencyContractWithSigner = currencyContract.connect(
        window.signer
      );
      const allowance = await currencyContractWithSigner.allowance(
        window.account,
        saleAdress
      );
      // console.log("allowance", allowance);

      if (allowance.lt(ethers.BigNumber.from(amountVal))) {
        const allowanceTransaction = await currencyContractWithSigner.approve(
          saleAdress,
          ethers.BigNumber.from(amountVal)
        );
        // console.log("allowanceTransaction", allowanceTransaction);

        const allowanceTransactionResponse = await allowanceTransaction.wait();
        // console.log(
        //   "allowanceTransactionResponse",
        //   allowanceTransactionResponse
        // );
      }

      const saleTransaction = await saleContractWithSigner.buyTokens(
        ethers.BigNumber.from(amountVal)
      );
      // console.log("saleTransaction", saleTransaction);
      const saleTransactionResponse = await saleTransaction.wait();
      // console.log("saleTransactionResponse", saleTransactionResponse);
      alert("Success!");
      const balance = await currencyContract.balanceOf(window.account);
      dispatch(setBalanceData(utils.formatEther(balance.toString())));
      setLoader(false);
      onClose();
    } catch (e) {
      alert("An Error Occured!");
      setLoader(false);
      return;
    }
  };
  return createPortal(
    <>
      <div style={OVERLAY_STYLES} />
      <div style={MODAL_STYLES} className="evergreen_modal buy">
        <div onClick={onClose} style={CLOSE_STYLES}>
          x
        </div>
        <div style={ABS_STYLES}>1 EGRN = 0.4 USDT</div>
        <div className="title">Buy Energreen</div>
        {balance !== undefined ? (
          <div className="max-info">
            <div
              style={BUY_STYLES.button}
              className="buy-btn max"
              onClick={() => {
                setUsdt(balance);
                setEgrn(balance / 0.4);
              }}
            >
              Add Max
            </div>
            <div className="info">
              Your Balance : <div>{balance} USDT</div>
            </div>
          </div>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              columnGap: "10px",
            }}
          >
            <>
              <CircularProgress />
              <div> Loading your balance ...</div>
            </>
          </Box>
        )}

        <div className="input-area">
          <div className="input-box">
            <label htmlFor="i1">USDT Amount</label>
            <div className="abs">
              <div className="currency">USDT</div>
              <input
                id="i1"
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9\.]/g, "");
                  setUsdt(value);
                  setEgrn(value / 0.4);
                  if (value === "") {
                    setEgrn("");
                  }
                }}
                value={usdt}
                style={BUY_STYLES.input}
                type="text"
              />
            </div>
          </div>
          <div className="input-box">
            <label htmlFor="i2">EGRN Amount</label>
            <div className="abs">
              <div className="currency">EGRN</div>
              <input
                id="i2"
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9\.]/g, "");
                  setUsdt(value * 0.4);
                  setEgrn(value);
                  if (value === "") {
                    setUsdt("");
                  }
                }}
                value={egrn}
                style={BUY_STYLES.input}
                type="text"
              />
            </div>
          </div>
        </div>

        <div
          style={BUY_STYLES.button}
          className="buy-btn new"
          onClick={() => {
            buyTokens();
          }}
        >
          Buy
        </div>

        {children}
      </div>
    </>,
    document.getElementById("modal")
  );
}
