import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import env from "react-dotenv";
import axios from "axios";
import { GetJWT } from "../components/GetJWT";

export const getSiteContent = createAsyncThunk(
  "getSiteContent",
  async (params, api) => {
    try {
      const response = await axios.get(env.API_URL + "site/getsitecontent", {
        headers: { Authorization: GetJWT() },
      });
      // console.log("getSiteContent", response.data);
      return response.data.data.list;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const updateSiteContent = createAsyncThunk(
  "updateSiteContent",
  async function(updateData, api) {
    try {
      const response = await axios.post(
        env.API_URL + "admin/updatesitecontent",
        {
          list: updateData,
        },
        {
          headers: { Authorization: GetJWT() },
        }
      );
      console.log("updateSiteContent", response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const getUserList = createAsyncThunk(
  "getUserList",
  async (filterParams, api) => {
    try {
      const response = await axios.get(env.API_URL + "admin/getUserList", {
        params: {
          search: filterParams.search,
          page: filterParams.page,
          pageSize: filterParams.pageSize,
        },
        headers: { Authorization: GetJWT() },
      });
      console.log("getUserList", response.data);
      return response.data.data.list;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const getUserActivity = createAsyncThunk(
  "getUserActivity",
  async (filterParams, api) => {
    try {
      const response = await axios.get(env.API_URL + "admin/getuseractivity", {
        params: {
          userId: filterParams.userId,
          page: filterParams.page,
          pageSize: filterParams.pageSize,
        },
        headers: { Authorization: GetJWT() },
      });
      console.log("getUserActivity", response.data);
      return response.data.data.list;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const getProfile = createAsyncThunk(
  "getProfile",
  async (params, api) => {
    try {
      const response = await axios.get(env.API_URL + "account/getprofile", {
        headers: { Authorization: GetJWT() },
      });
      console.log("getProfile", response.data);
      return response.data.data.user;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async function(email, api) {
    try {
      const response = await axios.post(
        env.API_URL + "account/forgotpassword",
        {
          email: email,
        },
        {
          headers: { Authorization: GetJWT() },
        }
      );
      console.log("forgotPassword", response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error.response.data.error;
    }
  }
);
export const resetPassword = createAsyncThunk(
  "resetPassword",
  async function(data, api) {
    try {
      const response = await axios.post(
        env.API_URL + "account/resetpassword",
        {
          code: data.code,
          newPassword: data.newPassword,
        },
        {
          headers: { Authorization: GetJWT() },
        }
      );
      console.log("resetPassword", response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error.response.data.error;
    }
  }
);
export const changePassword = createAsyncThunk(
  "changePassword",
  async function(data, api) {
    try {
      const response = await axios.post(
        env.API_URL + "account/changepassword",
        {
          oldPassword: data.oldPassword,
          newPassword: data.newPassword,
        },
        {
          headers: { Authorization: GetJWT() },
        }
      );
      console.log("changePassword", response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error.response.data.error;
    }
  }
);
export const setAdmin = createAsyncThunk(
  "setAdmin",
  async function(updateData, api) {
    try {
      const response = await axios.post(
        env.API_URL + "admin/setadmin",
        {
          id: updateData.id,
          makeAdmin: updateData.makeAdmin,
        },

        {
          headers: { Authorization: GetJWT() },
        }
      );
      console.log("setAdmin", response.data);
      api.dispatch(
        getUserList({
          page: 1,
          pageSize: 5,
          search: "",
        })
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const subscribe = createAsyncThunk(
  "subscribe",
  async function(email, api) {
    try {
      const response = await axios.post(
        env.API_URL + "newsletter/subscribe",
        {
          email: email,
        },
        {
          headers: { Authorization: GetJWT() },
        }
      );
      console.log("subscribe", response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error.response.data.error;
    }
  }
);

const siteContentSlice = createSlice({
  name: "siteContentSlice",
  initialState: {
    error: null,
    content: [],
    profileData: [],
    randomUser: null,
    userActivity: [],
    listData: [],
    kycData: [],
  },
  reducers: {
    setUser: (state, action) => {
      state.randomUser = action.payload;
    },
    resetUserActivity: (state, action) => {
      state.userActivity = [];
    },
    setBalanceData: (state, action) => {
      state.balance = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.profileData = action.payload;
    });
    builder.addCase(getSiteContent.fulfilled, (state, action) => {
      state.content = action.payload;
    });
    builder.addCase(getUserActivity.fulfilled, (state, action) => {
      state.userActivity = action.payload;
    });
    builder.addCase(getUserList.fulfilled, (state, action) => {
      state.listData = action.payload;
    });
  },
});
export default siteContentSlice.reducer;
export const { setUser, resetUserActivity, setBalanceData } =
  siteContentSlice.actions;
