import React, { Fragment } from "react";

export default function blog() {
  return (
    <Fragment>
      <div>
        <main id="site-content">
          <article
            className="post-17 page type-page status-publish hentry"
            id="post-17"
          >
            <div className="post-inner thin ">
              <div className="entry-content">
                <p className="coming-soon">Content coming soon!!</p>
              </div>
              {/* .entry-content */}
            </div>
            {/* .post-inner */}
            <div className="section-inner"></div>
            {/* .section-inner */}
          </article>
          {/* .post */}
        </main>
        {/* #site-content */}
      </div>
    </Fragment>
  );
}
