import './styles.css';

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import LoadingSVG from './LoadingSVG';

/*
  * title: Stake
*/

export default function StakeModal({ data, clearData }) {
  if (!data) return null;

  const [isInProgress, setIsInProgress] = useState(false);
  const [errorOccured, setErrorOccured] = useState(false);

  const [actions, setActions] = useState(data.actions)
  //
  // // Action:
  // // text
  // // onRun
  // // isDone
  //
  const nextUndone = useMemo(() => {
    return actions.find((action) => !action.isDone);
  }, [actions]);

  const buttonAction = useCallback(async () => {
    setIsInProgress(true);
    try {
      await nextUndone?.onRun();

      const newArray = [...actions];

      const index = newArray.findIndex((action) => action === nextUndone);

      newArray[index || 0].isDone = true;

      setActions(newArray);
    } catch (err) {
      setErrorOccured(true);

      if (nextUndone.onError) {
        nextUndone.onError(err);
      }
    }
    setIsInProgress(false);
  }, [nextUndone, actions]);

  useEffect(() => {
    if ((errorOccured && data.closeWhenError) || (data.closeWhenDone && !nextUndone)) {
      clearData();
    }
  }, [errorOccured, nextUndone, data, clearData]);


  return createPortal(
    <>
      <div className='backdrop' onClick={!isInProgress ? clearData : null} />
      <div className="modal evergreen_modal">

        <div className='modal-title'>{data.title}</div>

        {
          actions.map((action, index) => (
            <div key={index} className='action'>
              {
                errorOccured ?
                  <div className='action-error'>✗</div> :
                  action.isDone ?
                    <div className='action-done'>✓</div> :
                    (nextUndone === action && isInProgress) ?
                      <LoadingSVG /> :
                      null
              }
              <div className='action-text'>{action.text}</div>
            </div>
          ))
        }

        <button className='action-button' disabled={isInProgress} onClick={errorOccured ? clearData : !isInProgress ? nextUndone ? buttonAction : clearData : null}>{
          isInProgress ? '...' : errorOccured ? 'Error' : nextUndone?.text || 'Done!'
        }</button>

      </div>
    </>,
    document.getElementById("modal")
  );
}

{/* <div onClick={!isInProgress ? clearData : null} className='close-modal' > */ }
{/*   x */ }
{/* </div> */ }

{/* <div>{JSON.stringify(nextUndone)}</div> */ }
{/* <div>data:</div> */ }
{/* <div>{JSON.stringify(data)}</div> */ }
