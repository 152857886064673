import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import env from "react-dotenv";
import axios from "axios";
import { GetJWT } from "../components/GetJWT";
export const checkKyc = createAsyncThunk("checkKyc", async (params, api) => {
  try {
    const response = await axios.get(env.API_URL + "account/checkkyc", {
      headers: { Authorization: GetJWT() },
    });
    console.log("checkKyc", response.data.data);
    return response.data.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});
const kycSlice = createSlice({
  name: "kycSlice",
  initialState: { error: null, kycData: [] },
  extraReducers: (builder) => {
    builder.addCase(checkKyc.fulfilled, (state, action) => {
      state.kycData = action.payload;
    });
  },
});
export default kycSlice.reducer;
