import Index from "./pages/Index";
import Blog from "./pages/Blog";
import Ourprojects from "./pages/Ourprojects";
import Tokenomics from "./pages/Tokenomics";
import Kyc from "./pages/Kyc";
import Auth from "./pages/Auth";
import Claim from "./pages/Claim";
import Staking from "./pages/Staking";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { cookie, GetJWT } from "./components/GetJWT";
import { useEffect, useState } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import NotFound from "./components/NotFound";
import { useDispatch } from "react-redux";
import { getSiteContent } from "./redux/siteContentSlice";
import Edit from "./pages/Edit";
import UserList from "./pages/UserList";

function App() {
  const token = GetJWT();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSiteContent());
  }, []);
  let data = localStorage.getItem("userConfig");
  const [isLogged, setIsLogged] = useState(false);
  useEffect(() => {
    if (data === null) {
      cookie.remove("token_energreen");
      localStorage.removeItem("userConfig");
      setIsLogged(false);
    } else {
      setIsLogged(true);
    }
  }, [data, isLogged]);
  return (
    <div className="App">
      <Router>
        {token ? (
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Header setIsLogged={setIsLogged} isLogged={isLogged} />
                  <Outlet />
                  <Footer />
                </>
              }
            >
              <Route index element={<Index />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/our-projects" element={<Ourprojects />} />
              <Route path="/staking" element={<Staking />} /> 
              <Route path="/tokenomics" element={<Tokenomics />} />
              <Route path="/claim" element={<Claim />} />
              <Route path="/admin/edit" element={<Edit />} />
              <Route path="admin/user-list" element={<UserList />} />
              <Route
                path="/kyc"
                element={<Kyc setIsLogged={setIsLogged} isLogged={isLogged} />}
              />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        ) : (
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Header setIsLogged={setIsLogged} isLogged={isLogged} />
                  <Outlet />
                  <Footer />
                </>
              }
            >
              <Route index element={<Index />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/our-projects" element={<Ourprojects />} />
              <Route path="/tokenomics" element={<Tokenomics />} />
              <Route path="/claim" element={<Claim />} />
              <Route path="/staking" element={<Staking />} /> 
              <Route
                path="/kyc"
                element={<Kyc setIsLogged={setIsLogged} isLogged={isLogged} />}
              />
              <Route path="*" element={<NotFound />} />
            </Route>

            <Route path="auth" element={<Auth setIsLogged={setIsLogged} />} />
          </Routes>
        )}
      </Router>
    </div>
  );
}

export default App;
