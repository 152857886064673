import { unwrapResult } from "@reduxjs/toolkit";
import { useRef, useState } from "react";
import { Alert } from "@mui/material";
import { createPortal } from "react-dom";
import { useDispatch } from "react-redux";
import { changePassword } from "../../redux/siteContentSlice";
const MODAL_STYLES = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  backgroundColor: "#0B1A1C",
  zIndex: 1000,
  borderRadius: "10px",
  minHeight: 0,
};
const OVERLAY_STYLES = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0,.7)",
  zIndex: 1000,
};
const CLOSE_STYLES = {
  position: "absolute",
  top: "-10px",
  right: "10px",
  cursor: "pointer",
  fontSize: "30px",
};
export default function VideoModal({ open, children, onClose }) {
  if (!open) return null;
  return createPortal(
    <>
      <div style={OVERLAY_STYLES} />
      <div style={MODAL_STYLES} className="evergreen_modal auto">
        <div
          onClick={() => {
            onClose();
          }}
          style={CLOSE_STYLES}
        >
          x
        </div>
        <div>
          <iframe
            width="100%"
            height="400"
            con
            src="https://www.youtube.com/embed/K-4Zj-IzlH4"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        {/* <iframe
          width="100%"
          height="auto"
          con
          src="https://www.youtube.com/embed/X_N3In1ilx8"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe> */}
      </div>

      {children}
    </>,
    document.getElementById("modal")
  );
}
