import { ethers } from "ethers";
const IERC20 = require("../erc20.json");
// const STAKE = require("../stake.json");
// const SALE = require("../sale.json");
// const stakeAdress = "0xBB0Cf5778454c29bB42960Fd88F08090A3d45633";
// const saleAdress = "0xcb8987420fFD8291538e287d22C25f3A5E5D3678";
// const tokenAdress = "0x1701fBdc898cEf07a3ebcF5f875726CBD47E387E";
// const stakeContract = new ethers.Contract(stakeAdress, STAKE, signer);
// const saleContract = new ethers.Contract(saleAdress, SALE, signer);
// const tokenContract = new ethers.Contract(tokenAdress, IERC20, signer);
export const IsWalletConnect = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  window.provider = provider;
  const signer = provider.getSigner();
  window.signer = signer;

  const account = await signer.getAddress();
  window.account = account;
  return account;
};

export function claimABI() {
  return [
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_recipient",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "_claimLimit",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_claimStartTimestamp",
          "type": "uint256"
        },
        {
          "internalType": "bytes",
          "name": "signature",
          "type": "bytes"
        }
      ],
      "name": "claimTokens",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "claimed",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_recipient",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "_claimLimit",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_claimStartTimestamp",
          "type": "uint256"
        },
        {
          "internalType": "bytes",
          "name": "_signature",
          "type": "bytes"
        }
      ],
      "name": "testClaimInfo",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
  ];
}

export function stakeABI() {
  return  [
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_tokenAddress",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "_rewardHolder",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "_signer",
					"type": "address"
				}
			],
			"stateMutability": "nonpayable",
			"type": "constructor"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "address",
					"name": "_address",
					"type": "address"
				},
				{
					"indexed": false,
					"internalType": "uint64",
					"name": "stakeNo",
					"type": "uint64"
				},
				{
					"indexed": false,
					"internalType": "bool",
					"name": "value",
					"type": "bool"
				},
				{
					"indexed": false,
					"internalType": "uint64",
					"name": "timestamp",
					"type": "uint64"
				},
				{
					"indexed": false,
					"internalType": "string",
					"name": "extraData",
					"type": "string"
				}
			],
			"name": "ChangeAutoRenew",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "address",
					"name": "_signer",
					"type": "address"
				},
				{
					"indexed": false,
					"internalType": "address",
					"name": "_rewardHolder",
					"type": "address"
				},
				{
					"indexed": false,
					"internalType": "uint256",
					"name": "_maxStakeAmount",
					"type": "uint256"
				},
				{
					"indexed": false,
					"internalType": "bool",
					"name": "_withdrawStakeOnlyActive",
					"type": "bool"
				},
				{
					"indexed": false,
					"internalType": "bool",
					"name": "_withdrawNowActive",
					"type": "bool"
				}
			],
			"name": "ConfigChange",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "address",
					"name": "previousOwner",
					"type": "address"
				},
				{
					"indexed": true,
					"internalType": "address",
					"name": "newOwner",
					"type": "address"
				}
			],
			"name": "OwnershipTransferred",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "address",
					"name": "account",
					"type": "address"
				}
			],
			"name": "Paused",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "address",
					"name": "_address",
					"type": "address"
				},
				{
					"indexed": false,
					"internalType": "uint64",
					"name": "stakeNo",
					"type": "uint64"
				},
				{
					"indexed": false,
					"internalType": "uint256",
					"name": "amount",
					"type": "uint256"
				},
				{
					"indexed": false,
					"internalType": "enum IEnergreenStaking.StakeSource",
					"name": "source",
					"type": "uint8"
				},
				{
					"indexed": false,
					"internalType": "uint64",
					"name": "timestamp",
					"type": "uint64"
				},
				{
					"indexed": false,
					"internalType": "string",
					"name": "extraData",
					"type": "string"
				}
			],
			"name": "Stake",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "address",
					"name": "account",
					"type": "address"
				}
			],
			"name": "Unpaused",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "address",
					"name": "_address",
					"type": "address"
				},
				{
					"indexed": false,
					"internalType": "uint64",
					"name": "stakeNo",
					"type": "uint64"
				},
				{
					"indexed": false,
					"internalType": "uint256",
					"name": "stakeAmount",
					"type": "uint256"
				},
				{
					"indexed": false,
					"internalType": "uint256",
					"name": "rewardAmount",
					"type": "uint256"
				},
				{
					"indexed": false,
					"internalType": "enum IEnergreenStaking.StakeStatus",
					"name": "status",
					"type": "uint8"
				},
				{
					"indexed": false,
					"internalType": "uint64",
					"name": "timestamp",
					"type": "uint64"
				},
				{
					"indexed": false,
					"internalType": "string",
					"name": "extraData",
					"type": "string"
				}
			],
			"name": "Withdraw",
			"type": "event"
		},
		{
			"inputs": [
				{
					"internalType": "uint64",
					"name": "_stakeNo",
					"type": "uint64"
				},
				{
					"internalType": "bool",
					"name": "_autoRenew",
					"type": "bool"
				},
				{
					"internalType": "string",
					"name": "_extraData",
					"type": "string"
				}
			],
			"name": "changeAutoRenew",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_address",
					"type": "address"
				},
				{
					"internalType": "uint64",
					"name": "start",
					"type": "uint64"
				},
				{
					"internalType": "uint64",
					"name": "end",
					"type": "uint64"
				}
			],
			"name": "getInfo",
			"outputs": [
				{
					"internalType": "uint256[]",
					"name": "_chainData",
					"type": "uint256[]"
				},
				{
					"internalType": "uint256[]",
					"name": "_contractInfo",
					"type": "uint256[]"
				},
				{
					"internalType": "uint256[]",
					"name": "_amountList",
					"type": "uint256[]"
				},
				{
					"internalType": "uint256[]",
					"name": "_fixedRewardList",
					"type": "uint256[]"
				},
				{
					"internalType": "uint256[]",
					"name": "_nextRewardList",
					"type": "uint256[]"
				},
				{
					"internalType": "uint64[]",
					"name": "_createdAtList",
					"type": "uint64[]"
				},
				{
					"internalType": "uint64[]",
					"name": "_statusList",
					"type": "uint64[]"
				},
				{
					"internalType": "uint64",
					"name": "_stakeCount",
					"type": "uint64"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint64",
					"name": "_stakeNo",
					"type": "uint64"
				},
				{
					"internalType": "string",
					"name": "_extraData",
					"type": "string"
				}
			],
			"name": "harvestOldReward",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "owner",
			"outputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "pauseStaking",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "paused",
			"outputs": [
				{
					"internalType": "bool",
					"name": "",
					"type": "bool"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "renounceOwnership",
			"outputs": [],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint64",
					"name": "_stakeNo",
					"type": "uint64"
				},
				{
					"internalType": "string",
					"name": "_extraData",
					"type": "string"
				}
			],
			"name": "restake",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint64",
					"name": "_stakeNo",
					"type": "uint64"
				},
				{
					"internalType": "string",
					"name": "_extraData",
					"type": "string"
				}
			],
			"name": "restakeWithReward",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_maxStakeAmount",
					"type": "uint256"
				}
			],
			"name": "setMaxStakeAmount",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_holder",
					"type": "address"
				}
			],
			"name": "setRewardHolder",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_signer",
					"type": "address"
				}
			],
			"name": "setSigner",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "bool",
					"name": "_value",
					"type": "bool"
				}
			],
			"name": "setWithdrawNowActive",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "bool",
					"name": "_value",
					"type": "bool"
				}
			],
			"name": "setWithdrawStakeOnlyActive",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_amount",
					"type": "uint256"
				},
				{
					"internalType": "bool",
					"name": "_autoRenew",
					"type": "bool"
				},
				{
					"internalType": "bytes",
					"name": "sig",
					"type": "bytes"
				},
				{
					"internalType": "string",
					"name": "_extraData",
					"type": "string"
				}
			],
			"name": "stake",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"name": "stakeCount",
			"outputs": [
				{
					"internalType": "uint64",
					"name": "",
					"type": "uint64"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "newOwner",
					"type": "address"
				}
			],
			"name": "transferOwnership",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "unpauseStaking",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint64",
					"name": "_stakeNo",
					"type": "uint64"
				},
				{
					"internalType": "string",
					"name": "_extraData",
					"type": "string"
				}
			],
			"name": "withdraw",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint64",
					"name": "_stakeNo",
					"type": "uint64"
				},
				{
					"internalType": "string",
					"name": "_extraData",
					"type": "string"
				}
			],
			"name": "withdrawNow",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint64",
					"name": "_stakeNo",
					"type": "uint64"
				},
				{
					"internalType": "string",
					"name": "_extraData",
					"type": "string"
				}
			],
			"name": "withdrawStakeOnly",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		}
	];
}


export function ERC20ABI() {
  return [
    {
        "constant": true,
        "inputs": [],
        "name": "name",
        "outputs": [
            {
                "name": "",
                "type": "string"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            {
                "name": "_spender",
                "type": "address"
            },
            {
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [
            {
                "name": "",
                "type": "bool"
            }
        ],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
            {
                "name": "",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            {
                "name": "_from",
                "type": "address"
            },
            {
                "name": "_to",
                "type": "address"
            },
            {
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "transferFrom",
        "outputs": [
            {
                "name": "",
                "type": "bool"
            }
        ],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [],
        "name": "decimals",
        "outputs": [
            {
                "name": "",
                "type": "uint8"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [
            {
                "name": "_owner",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "name": "balance",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [],
        "name": "symbol",
        "outputs": [
            {
                "name": "",
                "type": "string"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            {
                "name": "_to",
                "type": "address"
            },
            {
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "transfer",
        "outputs": [
            {
                "name": "",
                "type": "bool"
            }
        ],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [
            {
                "name": "_owner",
                "type": "address"
            },
            {
                "name": "_spender",
                "type": "address"
            }
        ],
        "name": "allowance",
        "outputs": [
            {
                "name": "",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "payable": true,
        "stateMutability": "payable",
        "type": "fallback"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "name": "spender",
                "type": "address"
            },
            {
                "indexed": false,
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "Approval",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "name": "to",
                "type": "address"
            },
            {
                "indexed": false,
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "Transfer",
        "type": "event"
    }
];
}

export function saleABI() {
  return [];
}
