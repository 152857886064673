import { unwrapResult } from "@reduxjs/toolkit";
import { useRef, useState } from "react";
import { Alert } from "@mui/material";
import { createPortal } from "react-dom";
import { useDispatch } from "react-redux";
import { changePassword } from "../../redux/siteContentSlice";
const MODAL_STYLES = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  backgroundColor: "#0B1A1C",
  padding: "50px",
  zIndex: 1000,
  borderRadius: "10px",
  minHeight: 0,
};
const OVERLAY_STYLES = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0,.7)",
  zIndex: 1000,
};
const CLOSE_STYLES = {
  position: "absolute",
  top: 0,
  right: "10px",
  cursor: "pointer",
  fontSize: "30px",
};
const BUY_STYLES = {
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    rowGap: "10px",
  },
  input: {
    outline: "none",
    width: "100%",
    borderRadius: "10px",
  },
  button: {
    cursor: "pointer",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
  },
};
export default function ChangePasswordModal({ open, children, onClose }) {
  const dispatch = useDispatch();
  const oldPass = useRef();
  const newPass = useRef();
  const [success, setSuccess] = useState(false);
  const [alert, setAlert] = useState("");
  if (!open) return null;
  return createPortal(
    <>
      <div style={OVERLAY_STYLES} />
      <div style={MODAL_STYLES} className="evergreen_modal">
        <div
          onClick={() => {
            onClose();
            setAlert("");
            setSuccess(false);
          }}
          style={CLOSE_STYLES}
        >
          x
        </div>
        <div className="title">Change Password</div>
        {success ? (
          <Alert severity="success">Done</Alert>
        ) : (
          <form>
            <div className="form-group mt-3">
              <label htmlFor="old">Old Password</label>
              <input
                id="old"
                ref={oldPass}
                type="password"
                onChange={() => {
                  setAlert("");
                }}
                className="form-control mt-1"
                placeholder="Old Password"
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="newpass">New Password</label>
              <input
                id="newpass"
                onChange={() => {
                  setAlert("");
                }}
                ref={newPass}
                type="password"
                className="form-control mt-1"
                placeholder="New Password"
              />
            </div>
            <div className="d-grid gap-2 mt-3">
              <div
                className="btn btn-primary w-100"
                onClick={() => {
                  if (
                    newPass.current.value === "" ||
                    oldPass.current.value === ""
                  ) {
                    setAlert("Empty Area!");
                    return;
                  }
                  dispatch(
                    changePassword({
                      oldPassword: oldPass.current.value,
                      newPassword: newPass.current.value,
                    })
                  )
                    .then(unwrapResult)
                    .then((result) => {
                      setSuccess(true);
                      setTimeout(() => {
                        setAlert("");
                        setSuccess(false);
                        onClose();
                      }, 2000);
                    })
                    .catch((err) => {
                      console.log(err);
                      setAlert(err.message);
                      setSuccess(false);
                    });
                }}
              >
                Submit
              </div>
              <br />
              <br />
              {alert !== "" && <Alert severity="error">{alert}</Alert>}
            </div>
          </form>
        )}
      </div>

      {children}
    </>,
    document.getElementById("modal")
  );
}
