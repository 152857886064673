import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import env from "react-dotenv";
import { cookie, GetJWT } from "../components/GetJWT";
import { Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgotPassword, resetPassword } from "../redux/siteContentSlice";
import { unwrapResult } from "@reduxjs/toolkit";
export default function ({ setIsLogged }) {
  let [authMode, setAuthMode] = useState("signin");
  // sign in
  const emailSignIn = useRef();
  const passwordSignIn = useRef();
  // sign up
  const sponsorSignUp = useRef();
  const fullNameSignUp = useRef();
  const emailSignUp = useRef();
  const phoneSignUp = useRef();
  const passwordSignUp = useRef();
  const [signInAlert, setSignInAlert] = useState("");
  const [forgotAlert, setForgotAlert] = useState("");
  // forgot
  const emailForgot = useRef();
  const code = useRef();
  const newPassword = useRef();
  const [resetStep, setResetStep] = useState(1);
  const dispatch = useDispatch();
  const changeAuthMode = () => {
    setAuthMode(authMode === "signin" ? "signup" : "signin");
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setSignInAlert("");
    if (authMode === "signin") {
      const loginData = {
        email: emailSignIn.current.value,
        password: passwordSignIn.current.value,
      };
      login(loginData);
    }
    if (authMode === "signup") {
      const signUpData = {
        //sponsor: sponsorSignUp.current.value,
        name: fullNameSignUp.current.value,
        email: emailSignUp.current.value,
        phone: phoneSignUp.current.value,
        password: passwordSignUp.current.value,
      };
      signUp(signUpData);
    }
  };
  const navigate = useNavigate();
  const login = async function (loginData) {
    try {
      const response = await axios.post(env.API_URL + "account/login", {
        email: loginData.email,
        password: loginData.password,
      });
      let userArr = [];
      var data = response.data.data.user;
      userArr.push(JSON.stringify(data));
      localStorage.setItem("userConfig", userArr);
      // console.log("token var mi yok mu -->", !!cookie.get("token_energreen"));
      let cookieExpires = new Date(Date.now() + 1000 * 60 * 60 * 24);
      cookie.set("token_energreen", response.data.data.token, {
        path: "/",
        expires: cookieExpires,
      });
      localStorage.removeItem("seconds");
      setIsLogged(true);
      setSignInAlert("");
      navigate("/");
      return response.data;
    } catch (error) {
      console.log(error.response.data.error);
      setSignInAlert(error.response.data.error);
      setIsLogged(false);
    }
  };
  const signUp = async function (dataTest) {
    try {
      const response = await axios.post(
        env.API_URL + "account/create",
        {
          sponsor: dataTest.sponsor,
          name: dataTest.name,
          email: dataTest.email,
          phone: dataTest.phone,
          password: dataTest.password,
        },
        { headers: GetJWT() }
      );
      let userArr = [];
      var data = response.data.data.user;
      userArr.push(JSON.stringify(data));
      localStorage.setItem("userConfig", userArr);
      let cookieExpires = new Date(Date.now() + 1000 * 60 * 60 * 24);
      cookie.set("token_energreen", response.data.data.token, {
        path: "/",
        expires: cookieExpires,
      });
      localStorage.removeItem("seconds");
      setIsLogged(true);
      setSignInAlert("");
      navigate("/");
      return response.data;
    } catch (error) {
      console.log(error);
      setSignInAlert(error.response.data.error);
      setIsLogged(false);
    }
  };
  useEffect(() => {
    setForgotAlert("");
    setSignInAlert("");
  }, [authMode]);
  if (authMode === "signin") {
    return (
      <div className="Auth-form-container">
        <form className="Auth-form">
          <div className="Auth-form-content">
          <div className="text-center">
              <div
                size="sm"
                style={{ background: "#6dfecc", color: "black", border: 0 }}
                className="btn btn-primary w-100"
                onClick={(e) => {
                  e.preventDefault();
                  changeAuthMode();
                }}
              >
                Sign Up
              </div>
            </div>
            <h3 className="Auth-form-title" style={{ marginBottom: 0, marginTop: '1em'}}>Sign In Energreen</h3>
            <div className="form-group mt-3">
              <label>Email address</label>
              <input
                ref={emailSignIn}
                type="email"
                className="form-control mt-1"
                placeholder="Enter email"
              />
            </div>
            <div className="form-group mt-3">
              <label>Password</label>
              <input
                type="password"
                ref={passwordSignIn}
                className="form-control mt-1"
                placeholder="Enter password"
              />
            </div>
            <div className="d-grid gap-2 mt-3 d-flex align-items-center justify-content-between">
              <button
                style={{ background: "#6dfecc", color: "black", border: 0 }}
                type="submit"
                className="btn btn-primary"
                onClick={onSubmit}
              >
                Submit
              </button>
              <div
                onClick={(e) => {
                  setAuthMode("forgot");
                }}
                style={{ cursor: "pointer", color: "rgb(109, 254, 204)" }}
              >
                Forgot Password?
              </div>
            </div>
            <div className="d-grid gap-2 mt-4 d-flex align-items-center justify-content-center text-center">
              <a
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  color: "rgb(109, 254, 204)",
                }}
                href="/"
              >
                Back to Home Page
              </a>
            </div>
          </div>
          <br />
          {signInAlert !== "" && <Alert severity="error">{signInAlert}</Alert>}
        </form>
      </div>
    );
  }
  const onForgot = (e) => {
    e.preventDefault();
    if (resetStep === 1) {
      if (emailForgot.current.value === "") {
        setForgotAlert({ message: "Empty Area", type: "error" });
        return;
      }
      dispatch(forgotPassword(emailForgot.current.value))
        .then(unwrapResult)
        .then((result) => {
          console.log(result);
          setForgotAlert({ message: "Success!", type: "success" });
          emailForgot.current.value = "";
          setResetStep(2);
        })
        .catch((err) => {
          console.log(err);
          setForgotAlert({ message: err.message, type: "error" });
          emailForgot.current.value = "";
        });
    }
    if (resetStep === 2) {
      if (newPassword.current.value === "" || code.current.value === "") {
        setForgotAlert({ message: "Empty Area", type: "error" });
      } else {
        let data = {
          code: code.current.value,
          newPassword: newPassword.current.value,
        };
        dispatch(resetPassword(data))
          .then(unwrapResult)
          .then((result) => {
            setForgotAlert({ message: "Success!", type: "success" });
            setTimeout(() => {
              setResetStep(1);
              setAuthMode("login");
            }, 2000);
          })
          .catch((err) => {
            console.log(err);
            setForgotAlert({ message: err.message, type: "error" });
          });
      }
    }
  };

  if (authMode === "forgot") {
    return (
      <div className="Auth-form-container">
        <form className="Auth-form">
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Forgot Password</h3>
            {/* 
            <div className="text-center">
              <span className="link-primary" onClick={changeAuthMode}>
                <strong
                  style={{ background: "#6dfecc", color: "black", border: 0 }}
                  className="sign-up btn btn-primary w-100"
                >
                  Sign Up
                </strong>
              </span>
            </div>
            */}
            {resetStep === 1 && (
              <div className="form-group mt-3">
                <label>Email address</label>
                <input
                  onChange={() => {
                    setForgotAlert("");
                  }}
                  ref={emailForgot}
                  type="email"
                  className="form-control mt-1"
                  placeholder="Enter email"
                />
              </div>
            )}

            {resetStep === 2 && (
              <>
                <div className="form-group mt-3">
                  <label>Code</label>
                  <input
                    onChange={() => {
                      setForgotAlert("");
                    }}
                    ref={code}
                    type="text"
                    className="form-control mt-1"
                    placeholder="Enter code"
                  />
                </div>
                <div className="form-group mt-3">
                  <label>New Password</label>
                  <input
                    onChange={() => {
                      setForgotAlert("");
                    }}
                    ref={newPassword}
                    type="password"
                    className="form-control mt-1"
                    placeholder="New password"
                  />
                </div>
              </>
            )}
            <div className="d-grid gap-2 mt-3 d-flex align-items-center justify-content-between">
              <button
                style={{ background: "#6dfecc", color: "black", border: 0 }}
                type="submit"
                className="btn btn-primary"
                onClick={onForgot}
              >
                Submit
              </button>
            </div>
          </div>
          <br />
          {forgotAlert !== "" && (
            <Alert severity={forgotAlert.type}>{forgotAlert.message}</Alert>
          )}
        </form>
      </div>
    );
  }
  return (
    <div className="Auth-form-container">
      <form className="Auth-form">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Sign Up Energreen</h3>
          <div className="text-center">
            <button
              style={{ background: "#6dfecc", color: "black", border: 0 }}
              className="btn btn-primary w-100"
              onClick={(e) => {
                e.preventDefault();
                changeAuthMode();
              }}
            >
              Sign In
            </button>
          </div>
          {/* 
          <div className="form-group mt-3">
            <label htmlFor="sponsor">Sponsor</label>
            <input
              id="sponsor"
              ref={sponsorSignUp}
              type="email"
              className="form-control mt-1"
              placeholder="Sponsor Name"
            />
          </div>
          */}
          <div className="form-group mt-3">
            <label htmlFor="name">Full Name</label>
            <input
              id="name"
              ref={fullNameSignUp}
              type="text"
              className="form-control mt-1"
              placeholder="Full Name"
            />
          </div>
          <div className="form-group mt-3">
            <label htmlFor="email_up">Email address</label>
            <input
              id="email_up"
              ref={emailSignUp}
              type="email"
              className="form-control mt-1"
              placeholder="Email Address"
            />
          </div>
          <div className="form-group mt-3">
            <label htmlFor="phone">Phone</label>
            <input
              id="phone"
              ref={phoneSignUp}
              type="text"
              maxLength={11}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              className="form-control mt-1"
              placeholder="E.g 123 456 78 90"
            />
          </div>
          <div className="form-group mt-3">
            <label htmlFor="pwd_up">Password</label>
            <input
              id="pwd_up"
              ref={passwordSignUp}
              type="password"
              className="form-control mt-1"
              placeholder="Password"
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button
              style={{ background: "#6dfecc", color: "black", border: 0 }}
              type="submit"
              className="btn btn-primary"
              onClick={onSubmit}
            >
              Submit
            </button>
          </div>
        </div>
        <br />
        {signInAlert !== "" && <Alert severity="error">{signInAlert}</Alert>}
      </form>
    </div>
  );
}
