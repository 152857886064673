import "./styles.css";
import { useEffect, useState } from 'react';
import StakingNotification from "./StakingNotification";

export default function StakingNotifications({ notifications }) {
  console.log('notifications', notifications)

  return (
    <div className="staking-notification-container">
      {
        notifications.map((notification, index) => (
          <StakingNotification {...notification} key={index} >{index}</StakingNotification>
        ))
      }
    </div>
  )
}
