/* result: (success, fail, reload) */
export default function StakingNotification({ result, actionName, body }) {
  return <div className={`staking-notification ${result === 'fail' ? 'staking-notification-fail' : `staking-notification-success${result === 'reload' ? ' staking-notification-reload' : ''}`}`}>
    <div className="title">
      {
        result === 'fail' ?
          <div className='icon fail'>⚠</div> :
          <div className='icon success'>✓</div>
      }

      <div className="title">{result === 'fail' ? 'Failed' : actionName}</div>
    </div>

    {
      body ?
        <div>{body}</div> :
        <div>The action of <strong>{actionName}</strong> has {result === 'fail' ? 'failed' : 'succeed'}</div>
    }

    {
      result === 'reload' &&
      <div onClick={() => window.location.reload()} className="reload">
        <div className='icon'>⟳</div>
        <div>Reload</div>
      </div>
    }
  </div>;
};

  // Notificatins:
  // result: success, fail, reload
  // actionName: string

