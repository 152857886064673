import { minHeight } from "@mui/system";
import { unwrapResult } from "@reduxjs/toolkit";
import moment from "moment";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserActivity, setAdmin } from "../../redux/siteContentSlice";

const OVERLAY_STYLES = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0,.7)",
  zIndex: 1000,
};
const CLOSE_STYLES = {
  position: "absolute",
  top: 0,
  right: "10px",
  cursor: "pointer",
  fontSize: "30px",
};
const BUY_STYLES = {
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    rowGap: "10px",
  },
  input: {
    outline: "none",
    width: "100%",
    borderRadius: "10px",
  },
  button: {
    cursor: "pointer",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
  },
};

export default function ApproveRejectModal({ open, children, onClose }) {
  const { randomUser, userActivity } = useSelector(
    (store) => store.siteContent
  );
  const [role, setRole] = useState(null);
  useEffect(() => {
    if (randomUser === null) return;
    setRole(randomUser.role);
  }, [randomUser]);

  // const [role, setRole] = useState(randomUser.role);
  const dispatch = useDispatch();

  if (!open) return null;

  return createPortal(
    <>
      <div style={OVERLAY_STYLES} />
      <div className="evergreen_modal useracc">
        {/* close btn */}
        <div
          onClick={() => {
            onClose();
            window.location.reload();
          }}
          style={CLOSE_STYLES}
        >
          x
        </div>
        <div className="title">User Info</div>
        <div className="top">
          {role === "Admin" && (
            <div
              style={BUY_STYLES.button}
              className="buy-btn"
              onClick={() => {
                dispatch(
                  setAdmin({
                    id: randomUser.id,
                    makeAdmin: false,
                  })
                )
                  .then(unwrapResult)
                  .then((result) => {
                    setRole("User");
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              Remove admin permission!
            </div>
          )}

          {role !== "Admin" && (
            <div
              style={BUY_STYLES.button}
              className="buy-btn"
              onClick={() => {
                dispatch(
                  setAdmin({
                    id: randomUser.id,
                    makeAdmin: true,
                  })
                )
                  .then(unwrapResult)
                  .then((result) => {
                    setRole("Admin");
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              Make this user an admin!
            </div>
          )}
          <div
            style={BUY_STYLES.button}
            className="buy-btn"
            onClick={() => {
              dispatch(
                getUserActivity({
                  page: 1,
                  pageSize: 5,
                  userId: randomUser.id,
                })
              );
            }}
          >
            Refresh User Activity
          </div>
          <div className="admin-container fix">
            <div className="row-container">
              <>
                <div className="user-row head">
                  <div className="info">Name</div>
                  <div className="info">KYC STATUS</div>
                  <div className="info">Role</div>
                </div>
                <div className="user-row">
                  <div className="info" data-responsive="Name">
                    {randomUser.name}
                  </div>
                  <div className="info" data-responsive="KYC STATUS">
                    {randomUser.kycStatus}
                  </div>
                  <div className="info" data-responsive="Role">
                    {role}
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>

        <div className="bottom">
          {userActivity === null ? (
            <div style={{ textAlign: "center" }}>Empty history...</div>
          ) : (
            <>
              {userActivity.map((ac) => (
                <div
                  className="admin-container fix"
                  style={{ padding: 0, minHeight: 0 }}
                >
                  <div className="row-container">
                    <>
                      <div className="user-row head">
                        <div className="info">Type</div>
                        <div className="info">Message</div>
                        <div className="info">Created At</div>
                      </div>
                      <div className="user-row">
                        <div className="info" data-responsive="Type">
                          {ac.type}
                        </div>
                        <div className="info" data-responsive="Message">
                          {ac.msg}
                        </div>
                        <div className="info" data-responsive="Created At">
                          {moment(ac.createdAt).format("lll")}
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>

      {children}
    </>,
    document.getElementById("modal")
  );
}
