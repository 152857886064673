import { ArrowRight } from "@mui/icons-material";
import { ethers } from "ethers";
import { useCallback, useEffect, useState } from "react";
import { stakeABI } from "../Util";

const decimals = 18;
const stakePeriod = 2592000; // As seconds -  now 30 days
//const stakePeriod = 600; // As seconds -  now 10 minutes
const stakeStatuses = ["Empty", "Autorenew", "Manuel", "Withdrawed"];
const lockConfigPeriod = 172800; // 2 days as seconds, it represent the period which users can't disable autorenew before stake period
//const lockConfigPeriod = 120; // 2 minutes as seconds, it represent the period which users can't disable autorenew before stake period
const stakeAdress = "0x4FCf9Be1723b46F80E9Ebc11b9e9a439cfC8E6bD"; // Mainnet
//const stakeAdress = "0x2F2F96317F8Ce4E4b2e907914572f3d3bC68D176"; // Sepolia test staking
const energreenAddress = "0xDB8d6D3AC21e4efE3675BBB18514010AC9C5558F" // Mainnet 
//const energreenAddress = "0xeA6288faC266c3335EBF78B7e8D85A06A7cd88a8"; // Sepolia test token

export function timestampToLocalDateTime(timestamp) {
  return new Date(1000 * parseInt(timestamp)).toLocaleTimeString('tr-TR', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
}


const createStakeContract = async () => {
  const stakeContract = new ethers.Contract(
    stakeAdress,
    stakeABI(),
    window.provider
  );
  const stakeContractWithSigner = stakeContract.connect(window.signer);
  return stakeContractWithSigner;
};


async function handleWithdrawAll(stakeId) {
  console.log('first')
  const stakeContractWithSigner = await createStakeContract();
  console.log('second')

  let tx = await stakeContractWithSigner.withdraw(stakeId, "");
  console.log('third')
  await tx.wait();
  console.log('fourth')
  // window.location.reload(false);
};

async function handleRestakeWithoutReward(stakeId) {
  const stakeContractWithSigner = await createStakeContract();
  let tx = await stakeContractWithSigner.restake(stakeId, "");
  await tx.wait();
  // window.location.reload(false);
};

async function handleRestakeWithReward(stakeId) {
  const stakeContractWithSigner = await createStakeContract();
  let tx = await stakeContractWithSigner.restakeWithReward(stakeId, "");
  await tx.wait();
  // window.location.reload(false);
};

const handleToggleAutoRenew = async (stakeId, _autoRenew, _stake) => {
  if (
    _autoRenew == false &&
    (Date.now() / 1000 - _stake.createdAt) % stakePeriod >
    stakePeriod - lockConfigPeriod
  ) {
    // throw new Error({
    //   message: "You are in config lock time"
    // });
    throw new Error("pretty_error: You are in config lock time");
  } else {
    const stakeContractWithSigner = await createStakeContract();
    let tx = await stakeContractWithSigner.changeAutoRenew(
      stakeId,
      _autoRenew,
      ""
    );
    await tx.wait();
  }
};

function formatBigNumber(bigNumberHex) {
  let _bigNumber = ethers.BigNumber.from(bigNumberHex).toString();
  _bigNumber = ethers.utils.formatUnits(_bigNumber, decimals);
  return Number(_bigNumber).toFixed(2);
};

export default function StakeTableComponent({ stake, timestamp, index, setModalData, pushNotification, expandedStake, setExpandedStake }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleButtonPress = useCallback(async (func, actionName) => {
    setIsLoading(true);

    setModalData({
      title: actionName,
      closeWhenDone: true,
      closeWhenError: true,
      actions: [
        {
          text: actionName,
          onRun: async () => {
            const body = await func();
            pushNotification({
              result: "reload",
              actionName,
              body
            });
          },
          onError: (e) => {
            pushNotification({
              result: "fail",
              actionName,
              body: e.message.startsWith('pretty_error:') ? e.message : undefined
            });
          },
          isDone: false
        },
      ],
    });

    try {
    }
    catch (e) {
    }
    setIsLoading(false);
  }, []);

  return (
    <div className={`table-body ${expandedStake === index ? 'table-body-expanded' : ''}`}>
      <div onClick={() => setExpandedStake(index)} className="info">
        <ArrowRight className={`expand-arrow ${expandedStake === index ? 'expand-arrow-expanded' : ''}`} />

        <div>{formatBigNumber(stake.amount._hex)}</div>

        <div>
          {(
            Number(formatBigNumber(stake.fixedReward._hex)) +
            Number(formatBigNumber(stake.nextReward._hex))
          ).toFixed(2)}
        </div>

        <div>{timestampToLocalDateTime(stake.createdAt)}</div>

        <div>{stake.stakeStatus == 1
          ? "-"
          : timestampToLocalDateTime(new Date(
            (stake.createdAt +
              (stake.updateMonth + 1) * stakePeriod) *
            1000
          ).getTime() / 1000)
        }
        </div>

        <div>{stakeStatuses[stake.stakeStatus]}</div>
      </div>

      {
        expandedStake === index &&
        <div className="buttons">
          {stake.stakeStatus == 2 &&
            timestamp <
            stake.createdAt +
            (stake.updateMonth + 1) * stakePeriod ? (
            <button
              // onClick={() => handleToggleAutoRenew(index, true, stake)}
              disabled={isLoading}
              onClick={() =>
                handleButtonPress(() =>
                  handleToggleAutoRenew(index, true, stake)
                  , 'Enable AutoRenew')
              }
            >
              Enable AutoRenew
            </button>
          ) : null}

          {stake.stakeStatus == 1 ? (
            <button
              disabled={isLoading}
              // onClick={() => handleToggleAutoRenew(index, false, stake)}
              onClick={() =>
                handleButtonPress(() =>
                  handleToggleAutoRenew(index, false, stake)
                  , 'Disable Autorenew')
              }
            >
              Disable AutoRenew
            </button>
          ) : null}

          {stake.stakeStatus == 2 &&
            timestamp >
            stake.createdAt +
            (stake.updateMonth + 1) * stakePeriod ? (
            <button
              disabled={isLoading}
              onClick={() => handleButtonPress(() => handleWithdrawAll(index), 'Withdraw All')}
            >
              Withdraw All
            </button>
          ) : null}

          {stake.stakeStatus == 2 &&
            timestamp >
            stake.createdAt +
            (stake.updateMonth + 1) * stakePeriod ? (
            <button
              disabled={isLoading}
              onClick={() =>
                handleButtonPress(() =>
                  handleRestakeWithoutReward(index)
                  , 'Withdraw Reward and Restake')
              }
            >
              Withdraw Reward and Restake
            </button>
          ) : null}

          {stake.stakeStatus == 2 &&
            timestamp >
            stake.createdAt +
            (stake.updateMonth + 1) * stakePeriod ? (
            <button
              disabled={isLoading}
              onClick={() => handleButtonPress(() => handleRestakeWithReward(index), 'Restake with Reward')}
            >
              Restake with Reward
            </button>
          ) : null}
        </div>
      }

      {/* <div className="buttons"> */}
      {/*   <button>Withdraw All</button> */}
      {/*   <button>Withdraw Reward and Restake</button> */}
      {/*   <button>Restake With Reward</button> */}
      {/* </div> */}
    </div>
  )
}

/*   <tr>
     <td>{}</td>
     <td>
     </td>
   </tr>
 */
