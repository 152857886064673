import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { GetJWT } from "../components/GetJWT";
import { useDispatch } from "react-redux";
import {
  getProfile,
  getSiteContent,
  updateSiteContent,
} from "../redux/siteContentSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAutoAnimate } from "@formkit/auto-animate/react";
function Edit() {
  const [list, setList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState(null);
  const navigate = useNavigate();
  const token = GetJWT();
  const [animContainer] = useAutoAnimate();
  const [anim1] = useAutoAnimate();
  const [anim2] = useAutoAnimate();
  const [profileData, setProfileData] = useState({});
  const [selectContent, setSelectContent] = useState("default");
  const options = [
    { value: "home-banner", label: "Homepage Banner" },
    { value: "home-what-is-energreen", label: "What is energreen?" },
  ];
  const styles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      color: "black",
    }),
  };
  const [siteContent, setSiteContent] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProfile())
      .then(unwrapResult)
      .then((result) => {
        console.log(result);
        setProfileData(result);
      })
      .catch((err) => {
        console.log(err);
      });
    getData();
  }, []);
  function getData() {
    dispatch(getSiteContent())
      .then(unwrapResult)
      .then((result) => {
        console.log(result);
        setSiteContent(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // HOME REFS
  const homeRef1 = useRef();
  const homeRef2 = useRef();
  const homeRef3 = useRef();
  const homeRef4 = useRef();
  const homeRef5 = useRef();
  const homeRef6 = useRef();
  const homeRef7 = useRef();
  // WHAT IS REFS
  const whatRef1 = useRef();
  const whatRef2 = useRef();
  const whatRef3 = useRef();
  const whatRef4 = useRef();
  const whatRef5 = useRef();
  const whatRef6 = useRef();
  const whatRef7 = useRef();
  const whatRef8 = useRef();
  const whatRef9 = useRef();
  const whatRef10 = useRef();
  const whatRef11 = useRef();
  const whatRef12 = useRef();
  const whatRef13 = useRef();
  const whatRef14 = useRef();
  const whatRef15 = useRef();
  const whatRef16 = useRef();
  useEffect(() => {
    if (token) {
      console.log(profileData);
      if (profileData.role === "User") {
        navigate("/");
      }
    }
  }, [profileData]);
  return (
    <>
      {profileData !== {} && (
        <div className="admin-container">
          <h3>EDIT WEBSITE CONTENT</h3>
          <div className="row-container" ref={animContainer}>
            <label>Select Area</label>
            <Select
              options={options}
              styles={styles}
              onChange={(e) => setSelectContent(e.value)}
            />
            {selectContent === "home-banner" ? (
              <form className="row-container" ref={anim1}>
                <h4>Homepage Banner Area</h4>
                <label>Home banner title green :</label>
                <Form.Control
                  ref={homeRef1}
                  defaultValue={
                    siteContent.find(
                      (el) => el.key === "home_banner_title_green"
                    ).value
                  }
                  onChange={(e) => {
                    homeRef1.current.value = e.target.value;
                  }}
                />
                <label>Home banner title white :</label>
                <Form.Control
                  ref={homeRef2}
                  defaultValue={
                    siteContent.find(
                      (el) => el.key === "home_banner_title_white"
                    ).value
                  }
                  onChange={(e) => {
                    homeRef2.current.value = e.target.value;
                  }}
                />
                <label>Home banner list element 1:</label>
                <Form.Control
                  ref={homeRef3}
                  defaultValue={
                    siteContent.find(
                      (el) => el.key === "home_banner_line_1_(green)"
                    ).value
                  }
                  onChange={(e) => {
                    homeRef3.current.value = "a";
                  }}
                />
                <label>Home banner list element 2:</label>
                <Form.Control
                  ref={homeRef4}
                  defaultValue={
                    siteContent.find((el) => el.key === "home_banner_line_2")
                      .value
                  }
                  onChange={(e) => {
                    homeRef4.current.value = e.target.value;
                  }}
                />
                <label>Home banner list element 3:</label>
                <Form.Control
                  ref={homeRef5}
                  defaultValue={
                    siteContent.find((el) => el.key === "home_banner_line_3")
                      .value
                  }
                  onChange={(e) => {
                    homeRef5.current.value = e.target.value;
                  }}
                />
                <label>Home banner list element 4:</label>
                <Form.Control
                  ref={homeRef6}
                  defaultValue={
                    siteContent.find((el) => el.key === "home_banner_line_4")
                      .value
                  }
                  onChange={(e) => {
                    homeRef6.current.value = e.target.value;
                  }}
                />
                <label>Home banner list element 5:</label>
                <Form.Control
                  ref={homeRef7}
                  defaultValue={
                    siteContent.find((el) => el.key === "home_banner_line_5")
                      .value
                  }
                  onChange={(e) => {
                    homeRef7.current.value = e.target.value;
                  }}
                />
                <Button
                  variant="primary"
                  onClick={() => {
                    let updateData = [
                      {
                        key: "home_banner_title_green",
                        value: homeRef1.current.value,
                      },
                      {
                        key: "home_banner_title_white",
                        value: homeRef2.current.value,
                      },
                      {
                        key: "home_banner_line_1_(green)",
                        value: homeRef3.current.value,
                      },
                      {
                        key: "home_banner_line_2",
                        value: homeRef4.current.value,
                      },
                      {
                        key: "home_banner_line_3",
                        value: homeRef5.current.value,
                      },
                      {
                        key: "home_banner_line_4",
                        value: homeRef6.current.value,
                      },
                      {
                        key: "home_banner_line_5",
                        value: homeRef7.current.value,
                      },
                    ];
                    dispatch(updateSiteContent(updateData))
                      .then(unwrapResult)
                      .then((result) => {
                        console.log(result);
                        alert("updated");
                        getData();
                      })
                      .catch((err) => {
                        console.log(err);
                        alert("fail");
                      });
                  }}
                >
                  Update
                </Button>
              </form>
            ) : selectContent === "home-what-is-energreen" ? (
              <form className="row-container" ref={anim2}>
                <h4>What is energreen ?</h4>
                {/* 1 */}
                <div className="form-el-multiple">
                  <div>
                    <Form.Label htmlFor="t1">Title 1</Form.Label>
                    <Form.Control
                      id="t1"
                      defaultValue={
                        JSON.parse(
                          siteContent.find((el) => el.key === "what_is_line_1")
                            .value
                        ).title
                      }
                      ref={whatRef1}
                      onChange={(e) => {
                        whatRef1.current.value = e.target.value;
                      }}
                    />
                  </div>
                  <div>
                    <Form.Label htmlFor="d1">Description 1</Form.Label>
                    <Form.Control
                      ref={whatRef2}
                      defaultValue={
                        JSON.parse(
                          siteContent.find((el) => el.key === "what_is_line_1")
                            .value
                        ).description
                      }
                      onChange={(e) => {
                        whatRef2.current.value = e.target.value;
                      }}
                      id="d1"
                    />
                  </div>
                </div>
                {/* 2 */}
                <div className="form-el-multiple">
                  <div>
                    <Form.Label htmlFor="t2">Title 2</Form.Label>
                    <Form.Control
                      ref={whatRef3}
                      defaultValue={
                        JSON.parse(
                          siteContent.find((el) => el.key === "what_is_line_2")
                            .value
                        ).title
                      }
                      onChange={(e) => {
                        whatRef3.current.value = e.target.value;
                      }}
                      id="t2"
                    />
                  </div>
                  <div>
                    <Form.Label htmlFor="d2">Description 2</Form.Label>
                    <Form.Control
                      ref={whatRef4}
                      defaultValue={
                        JSON.parse(
                          siteContent.find((el) => el.key === "what_is_line_2")
                            .value
                        ).description
                      }
                      onChange={(e) => {
                        whatRef4.current.value = e.target.value;
                      }}
                      id="d2"
                    />
                  </div>
                </div>
                {/* 3 */}
                <div className="form-el-multiple textarea">
                  <Form.Label htmlFor="a1">Section 3</Form.Label>
                  <Form.Control
                    ref={whatRef5}
                    defaultValue={
                      siteContent.find((el) => el.key === "what_is_line_3")
                        .value
                    }
                    onChange={(e) => {
                      whatRef5.current.value = e.target.value;
                    }}
                    id="a1"
                    as="textarea"
                    rows={3}
                  />
                </div>
                {/* 4 */}
                <div className="form-el-multiple textarea">
                  <Form.Label htmlFor="a2">Section 4</Form.Label>
                  <Form.Control
                    ref={whatRef6}
                    defaultValue={
                      siteContent.find((el) => el.key === "what_is_line_4")
                        .value
                    }
                    onChange={(e) => {
                      whatRef6.current.value = e.target.value;
                    }}
                    id="a2"
                    as="textarea"
                    rows={3}
                  />
                </div>
                {/* 5 */}
                <div className="form-el-multiple textarea">
                  <Form.Label htmlFor="a3">Section 5</Form.Label>
                  <Form.Control
                    ref={whatRef7}
                    defaultValue={
                      siteContent.find((el) => el.key === "what_is_line_5")
                        .value
                    }
                    onChange={(e) => {
                      whatRef7.current.value = e.target.value;
                    }}
                    id="a3"
                    as="textarea"
                    rows={3}
                  />
                </div>
                {/* 6 */}
                <div className="form-el-multiple">
                  <div>
                    <Form.Label htmlFor="t6">Title 6</Form.Label>
                    <Form.Control
                      defaultValue={
                        JSON.parse(
                          siteContent.find((el) => el.key === "what_is_line_6")
                            .value
                        ).title
                      }
                      ref={whatRef8}
                      onChange={(e) => {
                        whatRef8.current.value = e.target.value;
                      }}
                      id="t6"
                    />
                  </div>
                  <div>
                    <Form.Label htmlFor="d6">Description 6</Form.Label>
                    <Form.Control
                      defaultValue={
                        JSON.parse(
                          siteContent.find((el) => el.key === "what_is_line_6")
                            .value
                        ).description
                      }
                      ref={whatRef9}
                      onChange={(e) => {
                        whatRef9.current.value = e.target.value;
                      }}
                      id="d6"
                    />
                  </div>
                </div>
                {/* 7 */}
                <div className="form-el-multiple textarea">
                  <Form.Label htmlFor="a7">Section 7</Form.Label>
                  <Form.Control
                    ref={whatRef10}
                    defaultValue={
                      siteContent.find((el) => el.key === "what_is_line_7")
                        .value
                    }
                    onChange={(e) => {
                      whatRef10.current.value = e.target.value;
                    }}
                    id="a7"
                    as="textarea"
                    rows={3}
                  />
                </div>
                {/* 8 */}
                <div className="form-el-multiple">
                  <div>
                    <Form.Label htmlFor="t8">Title 8</Form.Label>
                    <Form.Control
                      ref={whatRef11}
                      defaultValue={
                        JSON.parse(
                          siteContent.find((el) => el.key === "what_is_line_8")
                            .value
                        ).title
                      }
                      onChange={(e) => {
                        whatRef11.current.value = e.target.value;
                      }}
                      id="t8"
                    />
                  </div>
                  <div>
                    <Form.Label htmlFor="d8">Description 8</Form.Label>
                    <Form.Control
                      ref={whatRef12}
                      onChange={(e) => {
                        whatRef12.current.value = e.target.value;
                      }}
                      defaultValue={
                        JSON.parse(
                          siteContent.find((el) => el.key === "what_is_line_8")
                            .value
                        ).description
                      }
                      id="d8"
                    />
                  </div>
                </div>
                <div className="form-el-multiple">
                  <div>
                    <Form.Label htmlFor="t9">Title 9</Form.Label>
                    <Form.Control
                      ref={whatRef13}
                      onChange={(e) => {
                        whatRef13.current.value = e.target.value;
                      }}
                      defaultValue={
                        JSON.parse(
                          siteContent.find((el) => el.key === "what_is_line_9")
                            .value
                        ).title
                      }
                      id="t9"
                    />
                  </div>
                  <div>
                    <Form.Label htmlFor="d9">Description 9</Form.Label>
                    <Form.Control
                      ref={whatRef14}
                      onChange={(e) => {
                        whatRef14.current.value = e.target.value;
                      }}
                      defaultValue={
                        JSON.parse(
                          siteContent.find((el) => el.key === "what_is_line_9")
                            .value
                        ).description
                      }
                      id="d9"
                    />
                  </div>
                </div>
                <div className="form-el-multiple">
                  <div>
                    <Form.Label htmlFor="t10">Title 10</Form.Label>
                    <Form.Control
                      ref={whatRef15}
                      onChange={(e) => {
                        whatRef15.current.value = e.target.value;
                      }}
                      defaultValue={
                        JSON.parse(
                          siteContent.find((el) => el.key === "what_is_line_10")
                            .value
                        ).title
                      }
                      id="t10"
                    />
                  </div>
                  <div>
                    <Form.Label htmlFor="d10">Description 10</Form.Label>
                    <Form.Control
                      ref={whatRef16}
                      onChange={(e) => {
                        whatRef16.current.value = e.target.value;
                      }}
                      defaultValue={
                        JSON.parse(
                          siteContent.find((el) => el.key === "what_is_line_10")
                            .value
                        ).description
                      }
                      id="d10"
                    />
                  </div>
                </div>
                <Button
                  onClick={() => {
                    let formdata = [
                      {
                        key: "what_is_line_1",
                        value: JSON.stringify({
                          title: whatRef1.current.value,
                          description: whatRef2.current.value,
                        }),
                      },
                      {
                        key: "what_is_line_2",
                        value: JSON.stringify({
                          title: whatRef3.current.value,
                          description: whatRef4.current.value,
                        }),
                      },
                      {
                        key: "what_is_line_3",
                        value: whatRef5.current.value,
                      },
                      {
                        key: "what_is_line_4",
                        value: whatRef6.current.value,
                      },
                      {
                        key: "what_is_line_5",
                        value: whatRef7.current.value,
                      },
                      {
                        key: "what_is_line_6",
                        value: JSON.stringify({
                          title: whatRef8.current.value,
                          description: whatRef9.current.value,
                        }),
                      },
                      {
                        key: "what_is_line_7",
                        value: whatRef10.current.value,
                      },
                      {
                        key: "what_is_line_8",
                        value: JSON.stringify({
                          title: whatRef11.current.value,
                          description: whatRef12.current.value,
                        }),
                      },
                      {
                        key: "what_is_line_9",
                        value: JSON.stringify({
                          title: whatRef13.current.value,
                          description: whatRef14.current.value,
                        }),
                      },
                      {
                        key: "what_is_line_10",
                        value: JSON.stringify({
                          title: whatRef15.current.value,
                          description: whatRef16.current.value,
                        }),
                      },
                    ];
                    dispatch(updateSiteContent(formdata))
                      .then(unwrapResult)
                      .then((result) => {
                        console.log(result);
                        alert("updated");
                        getData();
                      })
                      .catch((err) => {
                        console.log(err);
                        alert("fail");
                      });
                  }}
                  variant="primary"
                >
                  Update
                </Button>
              </form>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
}

export default Edit;
