import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../assets/Kyc.css";
import { GetJWT } from "../components/GetJWT";
import { checkKyc } from "../redux/kycSlice";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import env from "react-dotenv";
export default function Kyc({ isLogged }) {
  const dispatch = useDispatch();
  const token = GetJWT();
  const [kycStatusData, setKycStatusData] = useState([]);
  const [animationParent] = useAutoAnimate();
  const navigate = useNavigate();
  useEffect(() => {
    if (token) {
      checkKyc();
    } else {
      navigate("/auth");
    }
  }, []);
  useEffect(() => {
    if (token)
      dispatch(checkKyc())
        .then(unwrapResult)
        .then((result) => {
          setKycStatusData(result);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  const loadBlockpassWidget = () => {
    const blockpass = new window.BlockpassKYCConnect(env.BLOCKPASS_CLIENT_ID, {
      refId: kycStatusData.userId,
    });
    blockpass.startKYCConnect();
    blockpass.on("KYCConnectLoad", () => {
      console.log("loaded");
    });
    blockpass.on("KYCConnectSuccess", () => {
      console.log("success");
      dispatch(checkKyc());
    });
    blockpass.on("KYCConnectCancel", () => {
      console.log("canceled");
      // iframe closed
    });
  };
  useEffect(() => {
    if (
      kycStatusData.kycStatus === "NotFound" ||
      kycStatusData.kycStatus === "Rejected"
    )
      loadBlockpassWidget();
  }, [kycStatusData]);
  return (
    <div className="admin-container">
      <div className="row-container">
        <p ref={animationParent}>
          KYC STATUS :
          {kycStatusData.kycStatus === "NotFound"
            ? " Not found"
            : kycStatusData.kycStatus === "InComplete"
            ? "Blockpass verifications pending"
            : kycStatusData.kycStatus === "Waiting"
            ? "Operator's review pending"
            : kycStatusData.kycStatus === "InReview"
            ? "In review by Operator"
            : kycStatusData.kycStatus === "Approved"
            ? "KYC application has been approved"
            : kycStatusData.kycStatus === "Rejected"
            ? "Operator has rejected one or more attributes"
            : kycStatusData.kycStatus === "Blocked"
            ? "Operator has blocked your profile!"
            : null}
        </p>
        <button
          className="btn btn-primary blockpass-btn"
          variant="primary"
          id="blockpass-kyc-connect"
        >
          Connect with Blockpass
        </button>
      </div>
    </div>
  );
}
