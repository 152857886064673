import { ethers, BigNumber } from "ethers";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import {
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { ArrowRight } from '@mui/icons-material';
import StakeModal from "../components/modals/stake-modal";
import { stakeABI } from "../components/Util";
import { ERC20ABI } from "../components/Util";
import { GetJWT } from "../components/GetJWT";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import StakeTableComponent from "../components/staking/StakeTableComponent";
import { connectWallet } from "../components/Metamask";
import { changeNetwork } from "../helpers/changeNetwork";
import StakingNotifications from "../components/staking/staking-notifications";
import StakeSwitch from "../components/staking/StakeSwitch";

const stakeAdress = "0x4FCf9Be1723b46F80E9Ebc11b9e9a439cfC8E6bD"; // Mainnet
// const stakeAdress = "0x2F2F96317F8Ce4E4b2e907914572f3d3bC68D176"; // Sepolia test staking
const energreenAddress = "0xDB8d6D3AC21e4efE3675BBB18514010AC9C5558F" // Mainnet 
// const energreenAddress = "0xeA6288faC266c3335EBF78B7e8D85A06A7cd88a8"; // Sepolia test token
// const decimals = 18;
//const sign = "0x15627233ecaaf618d710bbaa1889822f5d4e290d64e801b829e71a43db3e07ff6dba9c8723e88533433fe025eafc9e8980332a9541c3467db76d539385b576701c" ;

const ChainId = '0x1';// mainnet
// const ChainId = '0xaa36a7';// sepolia

//const sign = '0xc87cf5de727bd2b5b0e1a53cb1dbc196fece276fe6a91ed2bc6806dbc126349970b5e10938e05ec3076ea59628c01428cf331f15ed938e227170efd6427ed8381b'

export default function Staking() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [metamaskConnected, setMetamaskConnected] = useState(null);  // null, false, true
  const [stakingSigData, setStakingSigData] = useState(null);

  const [stakeData, setStakeData] = useState({});

  const [ethBalance, setEthBalance] = useState(0);
  const [egrnBalance, setEgrnBalance] = useState(0);
  const [stakeInput, setStakeInput] = useState(0);
  const [autoRenew, setAutoRenew] = useState(true);

  const [modalData, setModalData] = useState(null);
  const [loader, setLoader] = useState(true);
  const [notifications, setNotifications] = useState([]);

  const [expandedStake, setExpandedStake] = useState(0);

  // token check, metamask events
  useEffect(() => {
    if (!GetJWT())
      navigate("/auth");

    window.ethereum.on('disconnect', () => {
      setStakingSigData(null);
      setStakeData({});
    });
    window.ethereum.on('accountsChanged', (accounts) => {
      setLoader(true);
      setStakingSigData(null);
      setStakeData({});
      window.location.reload();
    });
    // window.ethereum.on('connect', () => {});
    window.ethereum.on('networkChanged', function(networkId) {
      console.log('networkChanged', networkId);
      if (!(window.ethereum.chainId && window.ethereum.chainId !== ChainId))
        showNetworkChangeModal();
      else
        setModalData(null);
    });
  }, []);

  function showNetworkChangeModal() {
    setModalData({
      title: 'Change Network',
      actions: [
        {
          text: 'Change Network',
          // onRun: () => console.log('Change Network'),
          onRun: async () => {
            console.log("Before changing");
            await changeNetwork(ChainId);
            console.log("After");
            navigate(0)
          },
          isDone: false
        },
      ],
    });
  }

  // check metamask connected
  useEffect(() => {
    async function f() {
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });
      setMetamaskConnected(accounts.length > 0);
    }
    f();
  }, [window.account]);

  // Notificatins:
  // type: success, error, refresh
  // actionName: string

  useEffect(() => {
    if (!window.ethereum || !window.ethereum.isConnected()) {
      // metamask kurulu degil
    } else if (window.ethereum.chainId && window.ethereum.chainId !== ChainId) {
      setLoader(false);
      showNetworkChangeModal();
    } else if (metamaskConnected === false) {  // undefined ise bu islem yapilmayacak, metamask extension hazir degildir
      setLoader(false);
      setModalData({
        title: 'Connect Wallet',
        actions: [
          {
            text: "Connect",
            onRun: async () => {
              await connectWallet();
              // navigate(0)
            },
            isDone: false,
          },
        ],
      });
    } else {
      setModalData(null);
    }
  }, [metamaskConnected, window.account, window.ethereum.chainId]);

  const getData = async () => {
    if (metamaskConnected !== true)
      return;
    const stakeContract = new ethers.Contract(
      stakeAdress,
      stakeABI(),
      window.provider
    );

    let data = await getContractInfo(
      stakeContract,
      window.signer,
      window.account,
      0,
      100
    );
    setStakeData(data);

    setLoader(false);
  };

  useEffect(() => {
    setTimeout(() => {
      getData();
    }, 200);
  }, [metamaskConnected]);

  const getEgrnBalance = async function() {
    try {
      const energreenContract = new ethers.Contract(
        energreenAddress,
        ERC20ABI(),
        window.provider
      );
      const egrnContractWithSigner = energreenContract.connect(window.signer);
      let balance = await egrnContractWithSigner.balanceOf(window.account);
      //let balance = await egrnContractWithSigner.balanceOf("0xDB8d6D3AC21e4efE3675BBB18514010AC9C5558F");
      let formattedEgrnBalance = ethers.utils.formatEther(balance.toString());
      formattedEgrnBalance = Number(formattedEgrnBalance).toFixed(2);
      setEgrnBalance(formattedEgrnBalance);
      /* console.log(formattedEgrnBalance); */
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getEgrnBalance();
    }, 1000);
  }, []);

  const getBalance = async function() {
    try {
      let balance = await window.provider.getBalance(window.account);
      let formattedEthBalance = ethers.utils.formatEther(balance.toString());
      formattedEthBalance = Number(formattedEthBalance).toFixed(3);

      setEthBalance(formattedEthBalance);
      /* console.log(formattedEthBalance); */
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getBalance();
    }, 1000);
  }, []);

  const handleAutoRenewChange = (event) => {
    setAutoRenew(event.target.checked);
  };

  const handleStakeChange = (event) => {
    event.target.value = event.target.value.replace(/[^0-9.]/g, "");
    setStakeInput(event.target.value);
  };

  async function stake() {
    if (!stakeInput) return;

    const egrnContract = new ethers.Contract(
      energreenAddress,
      ERC20ABI(),
      window.provider
    );


    let sigData;
    if (!stakingSigData) {
      const r = await axios.get(env.CLAIM_API_URL + "staking", {
        params: { wallet: window.account },
        headers: { Authorization: GetJWT() },
      });
      setStakingSigData(r.data);
      sigData = r.data;
    } else {
      sigData = stakingSigData;
    }
    if (sigData.status != "OK")
      return;

    let egrnContractWithSigner = egrnContract.connect(window.signer);

    let allowance = await egrnContractWithSigner.allowance(
      window.account,
      stakeAdress
    );

    allowance = Number(ethers.utils.formatEther(allowance));

    let modalData = {
      title: `Stake ${stakeInput} EGRN`,
      actions: [
        {
          text: "Stake",
          onRun: async () => {
            if (!sigData)
              return;

            const stakeContract = new ethers.Contract(
              stakeAdress,
              stakeABI(),
              window.provider
            );
            const stakeContractWithSigner = stakeContract.connect(window.signer);

            let stakeAmount = ethers.utils.parseEther(String(stakeInput));
            let tx = await stakeContractWithSigner.stake(
              stakeAmount,
              autoRenew,
              sigData.sig,
              ""
            );
            console.log("TX :  ", tx);
            await tx.wait();
            window.location.reload(false);
          },
          isDone: false,
        },
      ],
    }

    if (allowance < stakeInput) {
      modalData.actions = [
        {
          text: "Approve",
          onRun: async () => {
            let approveAmount = ethers.utils.parseEther(String(stakeInput));
            let tx = await egrnContractWithSigner.approve(stakeAdress, approveAmount);
            await tx.wait();
            console.log("approved");
          },
        },
        ...modalData.actions,
      ]
    }

    setModalData(modalData);
  }

  async function getContractInfo(contract, user, account, start, end) {
    const x = await contract.connect(user).getInfo(account, start, end);
    let d = {
      timestamp: parseInt(x[0][0]),
      userBalance: BigNumber.from(x[0][1]),
      maxStakeAmount: BigNumber.from(x[1][0]),
      totalStakeAmount: BigNumber.from(x[1][1]),
      totalWithdrawAmount: BigNumber.from(x[1][2]),
      stakeCount: parseInt(x[7]),
      stakes: [],
    };

    for (var i = 0; i < x[2].length; i++) {
      const status = parseInt(x[6][i]).toString(16);
      const stakeStatus = parseInt(status.slice(status.length - 1), 16); // status & 0xf;
      const source = parseInt(
        status.slice(status.length - 2, status.length - 1),
        16
      ); // (status >> 4) & 0xf;
      const updateMonth = parseInt(
        status.slice(status.length - 4, status.length - 2),
        16
      ); // (status >> 8) & 0xff;
      const highInterestRateMonths = parseInt(
        status.slice(status.length - 6, status.length - 4),
        16
      ); // (status >> 16) & 0xff;
      const check = parseInt(
        status.slice(status.length - 10, status.length - 6),
        16
      ); // (status >> 24) & 0xffff;
      //expect(check).to.equal(0x70ca);
      console.log("Check: " + check);

      d.stakes.push({
        amount: BigNumber.from(x[2][i]),
        fixedReward: BigNumber.from(x[3][i]),
        nextReward: BigNumber.from(x[4][i]),
        createdAt: parseInt(x[5][i]),
        stakeStatus: stakeStatus,
        source: source,
        updateMonth: updateMonth,
        highInterestRateMonths: highInterestRateMonths,
      });
    }
    console.log(d);
    return d;
  }


  return (
    <Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <StakeModal
        setLoader={setLoader}
        data={modalData}
        clearData={() => setModalData(null)}
      />
      <StakingNotifications notifications={notifications} />
      {!metamaskConnected ? (
        null
      ) : stakingSigData == null || stakingSigData?.status === "OK" ? (  // OK, WRONG_WALLET, NO_KYC
        <div className="staking-container">
          <div className="staking" style={{ padding: "10px" }}>
            <div className="col-md-12">
              <h2 className="staking-title">STAKE</h2>
              <p className="staking-balance">Balance: {ethBalance} ETH</p>

              <div className="input-container">
                <input
                  placeholder="Amount"
                  id="stake-input"
                  onChange={handleStakeChange}
                  className="staking-input"
                />
                <button disabled={parseInt(stakeInput || '0') <= 0} className="greenbtn mr-2" onClick={() => stake()}>
                  STAKE
                </button>
              </div>
              <div className="stake-input-bottom">
                <div className='auto-renew'>
                  <div>Auto Renew:</div>
                  <StakeSwitch
                    defaultChecked
                    checked={autoRenew}
                    onChange={handleAutoRenewChange}
                  />
                </div>

                <div className="balance">
                  <div>Balance:</div> <div className="number-value">{egrnBalance}</div> <div>EGRN</div>
                </div>
              </div>

              <div className="staking-notes">
                <h3 className="title">DETAILS</h3>

                <div className="note">
                  <div className="icon">i</div>
                  <div className="text"><span className="detail-text">Stake Period:</span>30 Days</div>
                </div>

                <div className="note">
                  <div className="icon">i</div>
                  <div className="text"><span className="detail-text">APY:</span>First 6 months <span className="detail-text" style={{ marginRight: '4px' }}>%20</span>last 6 months  <span className="detail-text">%12</span> </div>
                </div>

                <div className="note">
                  <div className="icon">i</div>
                  <div className="text"><span className="detail-text">Lock Time:</span>In last 2 days of stake period, autorenew can't be disabled. </div>
                </div>

                <div className="note">
                  <div className="icon">i</div>
                  <div className="text"><span className="detail-text">Autorenew:</span>Autorenewed stakes will be renewed at every end of staking period. When you disable autorenew, you can withdraw your rewards end of current staking month. </div>
                </div>

                <div className="note">
                  <div className="icon">i</div>
                  <div className="text"><span className="detail-text">Manuel:</span>You can withdraw your rewards in the end of staking period, or you can restake your reward and capital.</div>
                </div>

                <div className="note">
                  <div className="icon">i</div>
                  <div className="text"><span className="detail-text">Details:</span>Each stake is individual, rewards cannot be combined and withdrawn at once. </div>
                </div>

              </div>
            </div>
          </div>
          {loader ? (
            <p>Loading...</p>
          ) : (
            <div className="staking-table-container">
              <h2 className="staking-title">YOUR ACTIVE STAKES</h2>

              <div className="table-component">
                <div className="table-head">
                  <div>Amount</div>
                  <div>Reward</div>
                  <div>Created At</div>
                  <div>Ends At</div>
                  <div>Status</div>
                </div>
                {stakeData.stakes &&
                  stakeData.stakes.map((stake, index) =>
                    stake.stakeStatus == 3 ? null : (
                      <StakeTableComponent
                        stake={stake}
                        timestamp={stakeData.timestamp}
                        pushNotification={(nf) => {
                          setNotifications((prevNotifications) => [...prevNotifications, nf]);
                        }}
                        setModalData={setModalData}
                        expandedStake={expandedStake}
                        setExpandedStake={setExpandedStake}
                        index={index} />
                    )
                  )}
              </div>
            </div>
          )}
        </div>
      ) : stakingSigData?.status == "NO_KYC" ? (
        <div
          className="staking-container"
          style={{
            display: "block",
            marginTop: "270px",
            marginBottom: "150px",
          }}
        >
          <h2 className="error-message">You dont have KYC.</h2>
          <br />
          <br />
          <h2 className="error-message">Please do the KYC first. </h2>

          <button onClick={() => navigate('/kyc')} className="do-kyc-button">Do KYC</button>
        </div>
      ) : stakingSigData?.status == "WRONG_WALLET" ? (
        <p>wrong wallet, use {stakingSigData.wallet}</p>
      ) : (
        null // undefined stakingSigData.status
      )}
    </Fragment>
  );
}

/*
<div className="table-component">
  <div className="table-head">
    <div>Amount</div>
    <div>Reward</div>
    <div>Created At</div>
    <div>Ends At</div>
    <div>Status</div>
  </div>

  <div className={`table-body ${expandedStake === 0 ? 'table-body-expanded' : ''}`}>
    <div onClick={() => setExpandedStake(0)} className="info">
      <ArrowRight className={`expand-arrow ${expandedStake === 0 ? 'expand-arrow-expanded' : ''}`} />

      <div>258.00</div>
      <div>7...30.63</div>
      <div>19/07/23 18:12</div>
      <div>19/07/23 18:12</div>
      <div>Autorenew</div>
    </div>

    <div className="buttons">
      <button>Withdraw All</button>
      <button>Withdraw Reward and Restake</button>
      <button>Restake With Reward</button>
    </div>
  </div>

  <div className={`table-body ${expandedStake === 1 ? 'table-body-expanded' : ''}`}>
    <div onClick={() => setExpandedStake(1)} className="info">
      <ArrowRight className={`expand-arrow ${expandedStake === 1 ? 'expand-arrow-expanded' : ''}`} />

      <div>258.00</div>
      <div>7...30.63</div>
      <div>19/07/23 18:12</div>
      <div>19/07/23 18:12</div>
      <div>Autorenew</div>
    </div>

    <div className="buttons">
      <button>Withdraw All</button>
      <button>Withdraw Reward and Restake</button>
      <button>Restake With Reward</button>
    </div>
  </div>

</div>
*/
