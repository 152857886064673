import { display } from "@mui/system";
import React, { useEffect, useState } from "react";
import BoxInfoModal from "./modals/BoxInfoModal";
import { withNamespaces } from "react-i18next";

function HomeGridBox(props) {
  const { icon, title, textData, html = false, t } = props;
  const [htmlCont, setHtmlCont] = useState("");
  const [test1, setTest1] = useState(null);
  const [test2, setTest2] = useState(null);
  useEffect(() => {
    const parser = new DOMParser();
    if (html.props !== undefined) {
      const doc = parser.parseFromString(
        html.props.dangerouslySetInnerHTML.__html,
        "text/html"
      );
      const h3Title = doc.querySelector("h3");
      const pTitle = doc.querySelector("p");
      if (h3Title === null) return;

      setTest1(h3Title);
      setTest2(pTitle);
    }
  }, [html]);

  const [data, setData] = useState([]);
  useEffect(() => {
    if (test1 === null || test2 === null) {
      setData([icon, textData, title]);
    } else {
      setData([icon, test2.textContent, test1.textContent, html]);
    }
  }, [test1, test2]);

  const [open, setOpen] = useState(false);
  const close = () => {
    setOpen(false);
  };
  const openModal = () => {
    setOpen(true);
  };

  return (
    <>
      <BoxInfoModal open={open} onClose={close} data={data} />
      <div
        className={"energreen-grid-box engpoints energreen-grid col-xs-6"}
        style={{ cursor: "pointer" }}
        onClick={() => {
          openModal();
        }}
      >
        <img src={icon} alt="icon" />
        <div>
          <h3>{test1 === null ? t(title) : test1.textContent}</h3>
        </div>
        {/* <button
          style={{ background: "transparent" }}
         
          className="outlinebtn" 
          
        >
          See more
        </button> */}
      </div>
    </>
  );
}

export default withNamespaces()(HomeGridBox);
