import React, { useEffect, useRef, useState } from "react";
import { connectWallet } from "./Metamask";
import { ERC20ABI, IsWalletConnect } from "./Util";
import { cookie, GetJWT } from "./GetJWT";
import BuyEngreenModal from "./modals/BuyEngreenModal";
import { NavLink } from "react-router-dom";
import { Backdrop, CircularProgress, Fade, Tooltip } from "@mui/material";
import { useDispatch, useStore } from "react-redux";
import { getProfile, setBalanceData } from "../redux/siteContentSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import useClickOutside from "./useClickOutside";
import ChangePasswordModal from "./modals/ChangePasswordModal";
import DoneIcon from "@mui/icons-material/Done";
import { checkKyc } from "../redux/kycSlice";
import logo from "../assets/enerlogo2.png";
import pdf from "../assets/Energreen-V5.pdf";
import doc from "../assets/whitepaper.pdf";
import { ethers, utils } from "ethers";
import ModalVideo from "react-modal-video";
import i18n from "../assets/i18n";
import { withNamespaces } from "react-i18next";

import AutoLogout from "./AutoLogout";
function Header({ setIsLogged, isLogged, t }) {
  const usdtAdress = "0x1701fBdc898cEf07a3ebcF5f875726CBD47E387E";
  const [isOpen, setIsOpen] = useState(false);
  const [walletAddress, setWalletAdress] = useState(null);

  const [loader, setLoader] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [username, setUsername] = useState("");
  const [showTick, setShowTick] = useState(false);
  const [lngName, setLngName] = useState("TR");
  const token = GetJWT();
  const isMobile = window.innerWidth < 991 ? true : false;
  const [isScrolled, setIsScrolled] = useState(false);

  const [changeModal, setChangeModal] = useState(false);
  async function getBalance() {
    if (walletAddress === undefined) return;
    const currencyContract = new ethers.Contract(
      usdtAdress,
      ERC20ABI(),
      window.provider
    );
    //const balance = await currencyContract.balanceOf(walletAddress);
    //dispatch(setBalanceData(utils.formatEther(balance.toString())));
  }
  useEffect(() => {
    getBalance();
  }, [walletAddress]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (token)
      dispatch(getProfile())
        .then(unwrapResult)
        .then((result) => {
          setProfileData(result);
          setUsername(result.name);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  const ref = useRef(null);
  useClickOutside(ref, () => setMenuVisible(false));
  const [admin, setAdmin] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  useEffect(() => {
    if (token) {
      if (profileData.role === "Admin" || profileData.role === "SuperAdmin") {
        setAdmin(true);
      } else {
        setAdmin(false);
      }
    }
  }, [profileData]);
  useEffect(() => {
    if (token)
      dispatch(checkKyc())
        .then(unwrapResult)
        .then((result) => {
          if (result.kycStatus === "Approved") {
            setShowTick(true);
          } else {
            setShowTick(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setShowTick(false);
        });

    (async () => {
      let walletData = await IsWalletConnect();
      setWalletAdress(walletData.substr(0, 8) + "..." + walletData.substr(-6, 6));
    })()

    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        setWalletAdress(accounts[0]);
      });
    }
    
    window.addEventListener("scroll", () => {
      setIsScrolled(window.scrollY >= 80);
    });
  }, []);

  useEffect(() => {
    if (window.account) {
      setWalletAdress(window.account);
    }
  }, [window.account])

  function logout() {
    cookie.remove("token_energreen");
    localStorage.removeItem("userConfig");
    setIsLogged(false);
  }


  const whitepaperOpenPDF = () => {
    const pdfWindow = window.open("Whitepaper");
    const title = "Whitepaper PDF";
    const URI = "whitepaper.pdf";
    const html = `
      <html>
        <head>
          <title>${title}</title>
        </head>
        <body style="margin:0">
          <embed width="100%" height="100%" src=${doc} type="application/pdf">
        </body>
      </html>
    `;

    pdfWindow.document.write(html);
    pdfWindow.document.close();
    pdfWindow.history.pushState(null, null, URI);
  };
  const litepaperOpenPDF = () => {
    const pdfWindow = window.open("Litepaper");
    const title = "Litepaper PDF";
    const URI = "litepaper.pdf";
    const html = `
      <html>
        <head>
          <title>${title}</title>
        </head>
        <body style="margin:0">
          <embed width="100%" height="100%" src=${pdf} type="application/pdf">
        </body>
      </html>
    `;

    pdfWindow.document.write(html);
    pdfWindow.document.close();
    pdfWindow.history.pushState(null, null, URI);
  };
  const changeLanguage = (lng) => {
    if (lng === "TR") {
      i18n.changeLanguage("tr");
      setLngName("EN");
    } else if (lng === "EN") {
      i18n.changeLanguage("en");
      setLngName("TR");
    }
  };
  return (
    <>
      {isLogged && <AutoLogout refId="autologout" />}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <BuyEngreenModal
        setLoader={setLoader}
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />

      <header className={`header ${isScrolled ? "header-scrolled" : ""}`}>
        <div className="container">
          <div className="row toprow">
            <div className="col-md-12 d-flex align-items-center">
              <a className="logo" href="/">
                <img src={logo} />
              </a>
              <nav className="navbar navbar-expand-lg">
                <a className="greenbtn mr-3 mob-lang-btn" onClick={() => changeLanguage(lngName)} > {lngName} </a>
                <a
                        className="greenbtn mr-5 mob-lang-btn"
                        rel="noopener"
                        href="/auth"
                      >
                        {t("header.translate4")}
                      </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon">
                    <i className="fa fa-bars" aria-hidden="true" />
                  </span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarNavDropdown"
                >
                  <ul id="menu-headermenu" className="navbar-nav">
                    <li
                      id="menu-item-24"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-24 nav-item"
                    >
                      <a className="nav-link" href="/">
                        {t("header.translate1")}
                      </a>
                    </li>
                    {/* <li
                      id="menu-item-23"
                      className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-9 current_page_item menu-item-23 nav-item"
                    >
                      <a
                        className="nav-link"
                        href="/our-projects/"
                        aria-current="page"
                      >
                      Projects
                      </a>
                    </li> */}
                    {/* <li
                      id="menu-item-22"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-22 nav-item"
                    >
                      <a className="nav-link" href="/tokenomics/">
                        Tokenomics
                      </a>
                    </li> */}
                    <li
                      id="menu-item-464"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-464 nav-item"
                    >
                      {isMobile ? (
                        <a
                          href={pdf}
                          className="nav-link"
                          target="_blank"
                          without
                          rel="noopener noreferrer"
                        >
                          {t("header.translate2")}
                        </a>
                      ) : (
                        <a
                          onClick={litepaperOpenPDF}
                          className="nav-link"
                          target="_blank"
                          without
                          rel="noopener noreferrer"
                        >
                          {t("header.translate2")}
                        </a>
                      )
                      }

                    </li>
                    <li
                      id="menu-item-464"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-20 nav-item"
                    >
                      {isMobile ? (
                        <a
                          href={doc}
                          className="nav-link"
                          target="_blank"
                          without
                          rel="noopener noreferrer"
                        >
                          {t("header.translate3")}
                        </a>
                      ) : (
                        <a
                          onClick={whitepaperOpenPDF}
                          className="nav-link"
                          target="_blank"
                          without
                          rel="noopener noreferrer"
                        >
                          {t("header.translate3")}
                        </a>

                      )
                      }

                    </li>
                    <li
                      id="menu-item-464"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-20 nav-item"
                    >
                      <a
                        href="https://coinmarketcap.com/currencies/energreen/#Markets"
                        className="nav-link buy-egrn-button"
                        target="_blank"
                        without
                        rel="noopener noreferrer"
                      >
                        {t("header.translate15")}
                      </a>

                    </li>
                    {/* 
                    <li
                    id="menu-item-464"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-464 nav-item"
                    >
                      <a
                        className="nav-link"
                        target="_blank"
                        rel="noopener"
                        href={pdf}
                      >
                        EGRN
                      </a>
                    </li>
                    
                    <li
                      id="menu-item-464"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-464 nav-item dropdownNotShow"
                    >
                      <a
                        className="nav-link"
                        target="_blank"
                        rel="noopener"
                        href="/auth"
                      >
                        {t("header.translate4")}
                      </a>
                    </li>
                    */}
                    <li
                      id="menu-item-464"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-464 nav-item dropdownNotShow"
                    >
                      <div
                        className="dropdown"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <a
                          id="connectBtn"
                          className="dropdown-toggle"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          style={{ display: "inline-block" }}
                        >
                          {walletAddress || t("header.translate14")}
                        </a>
                        <p />
                        <div
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                          style={{
                            background: "#0b2c34",
                            position: "absolute",
                          }}
                        >
                          {!walletAddress ? (
                            <a
                              onClick={connectWallet}
                              className="dropdown-item"
                            >
                              {t("header.translate5")}
                            </a>
                          ) : null}

                          <a
                            href="/kyc"
                            className="dropdown-item"
                            style={{
                              marginTop: "0 !important",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {t("header.translate6")}
                            {showTick && <DoneIcon fontSize="small" />}
                          </a>
                          {token && (
                            <>
                              <ChangePasswordModal
                                open={changeModal}
                                onClose={() => {
                                  setChangeModal(false);
                                }}
                              />
                              <a
                                className="dropdown-item"
                                onClick={() => {
                                  setChangeModal(true);
                                }}
                                style={{ fontSize: "8px" }}
                              >
                                {t("header.translate7")}
                              </a>
                            </>
                          )}
                          <Tooltip
                            // title="TBA"
                            followCursor
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                          >
                            <NavLink to="staking"
                              className="dropdown-item"
                            >
                              {t("header.translate8")}
                            </NavLink>
                          </Tooltip>

                          <NavLink to="claim" className="dropdown-item">
                            {t("header.translate9")}
                          </NavLink>
                          <Tooltip
                            // title="TBA"
                            followCursor
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                          >
                            <a
                              className="dropdown-item"
                              style={{ fontSize: "10px", cursor: "default" }}
                            >
                              {t("header.translate10")}
                            </a>
                          </Tooltip>
                        </div>
                      </div>
                    </li>

                    {admin && (
                      <li
                        onClick={() => setMenuVisible(!menuVisible)}
                        id="menu-item-19"
                        className="menu-item dropdown menu-item-type-post_type menu-item-object-page menu-item-19 nav-item"
                      >
                        <div style={{ cursor: "pointer" }}>
                          <a className="nav-link dropdown">
                            {t("header.translate11")}
                          </a>
                        </div>
                        {menuVisible && (
                          <div className="dropdown-bar admin">
                            <NavLink
                              to="admin/edit"
                              onClick={() => setIsOpen(false)}
                            >
                              <div className="dropdown-element">
                                {t("header.translate12")}
                              </div>
                            </NavLink>
                            <NavLink to="admin/user-list">
                              <div className="dropdown-element ">
                                {t("header.translate13")}
                              </div>
                            </NavLink>
                          </div>
                        )}
                      </li>
                    )}
                  </ul>
                </div>
              </nav>
              <div
                className="signacc"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <a
                  className="greenbtn mr-2"
                  onClick={() => changeLanguage(lngName)}
                >
                  {lngName}
                </a>
                {isLogged ? (

                  <NavLink
                    onClick={() => logout()}
                    className="greenbtn"
                    to="/auth"
                  >
                    <div
                      onMouseEnter={(e) => {
                        setUsername("Logout");
                      }}
                      onMouseLeave={(e) => {
                        setUsername(profileData.name);
                      }}
                    >
                      {username}
                    </div>
                  </NavLink>
                ) : (
                <NavLink className="greenbtn" to="/auth">
                  {t("header.translate4")}
                </NavLink>
                )}

                <div
                  className="dropdown"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <a
                    id="connectBtn"
                    className="outlinebtn ml-2 dropdown-toggle"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{ display: "inline-block" }}
                  >
                    {walletAddress ? `${walletAddress.slice(0, 5)}...${walletAddress.slice(-5)}` : t("header.translate14")}
                  </a>
                  <p />
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                    style={{ background: "#0b2c34", borderRadius: "20px" }}
                  >
                    {walletAddress === undefined ? (
                      <a
                        onClick={connectWallet}
                        className="dropdown-item"
                      >
                        {t("header.translate5")}
                      </a>
                    ) : null}

                    <a
                      href="/kyc"
                      className="dropdown-item"
                      style={{
                        marginTop: "0 !important",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {t("header.translate6")}
                      {showTick && <DoneIcon fontSize="small" />}
                    </a>
                    {token && (
                      <>
                        <ChangePasswordModal
                          open={changeModal}
                          onClose={() => {
                            setChangeModal(false);
                          }}
                        />
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            setChangeModal(true);
                          }}
                          style={{ fontSize: "8px" }}
                        >
                          {t("header.translate7")}
                        </a>
                      </>
                    )}
                    <Tooltip
                      // title="TBA"
                      followCursor
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 600 }}
                    >
                      <NavLink to="staking"
                        className="dropdown-item"
                      >
                        {t("header.translate8")}
                      </NavLink>
                    </Tooltip>

                    <NavLink to="claim" className="dropdown-item">
                      {t("header.translate9")}
                    </NavLink>
                    <Tooltip
                      // title="TBA"
                      followCursor
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 600 }}
                    >
                      <a
                        className="dropdown-item"
                        style={{ fontSize: "10px", cursor: "default" }}
                      >
                        {t("header.translate10")}
                      </a>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
            {/*col-md-12*/}
          </div>
          {/*row*/}
        </div>
      </header>
    </>
  );
}

export default withNamespaces()(Header);
