import { unwrapResult } from "@reduxjs/toolkit";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { GetJWT } from "../components/GetJWT";
import {
  getProfile,
  getUserList,
  resetUserActivity,
  setUser,
} from "../redux/siteContentSlice";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import ApproveRejectModal from "../components/modals/ApproveRejectModal";
function UserList() {
  const [anim] = useAutoAnimate();
  const [page, setPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [searchInput, setSearchInput] = useState();
  const [latencySearch] = useDebounce(searchInput, 500);
  const [list, setList] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = GetJWT();
  const [loadMore, showLoadMore] = useState(true);
  const [profileData, setProfileData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const { randomUser, listData } = useSelector((x) => x.siteContent);
  console.log(listData);
  useEffect(() => {
    if (token) {
      dispatch(getProfile())
        .then(unwrapResult)
        .then((result) => {
          console.log(result);
          setProfileData(result);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    dispatch(getUserList({ page: page, search: "", pageSize: 5 }))
      .then(unwrapResult)
      .then((result) => {
        setList(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    console.log(profileData);
    if (profileData.role === "User") {
      navigate("/");
    }
  }, [profileData]);
  useEffect(() => {
    showLoadMore(true);
    if (latencySearch === undefined) return;
    dispatch(
      getUserList({
        page: latencySearch === "" ? page : searchPage,
        search: latencySearch,
        pageSize: 5,
      })
    )
      .then(unwrapResult)
      .then((result) => {
        setList(result);
        setPage(1);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [latencySearch]);
  return (
    <>
      {profileData !== {} && (
        <>
          <ApproveRejectModal
            open={isOpen}
            user={randomUser}
            onClose={() => {
              dispatch(setUser(null));
              dispatch(resetUserActivity());
              setIsOpen(false);
            }}
          />
          <div className="admin-container">
            <h2 className="title">User List</h2>
            <div className="row-container">
              <div className="control">
                <div className="search">
                  <label htmlFor="search_user">Search User</label>
                  <input
                    value={searchInput}
                    onChange={(e) => {
                      setSearchInput(e.target.value);
                    }}
                    id="search_user"
                    type="text"
                  />
                </div>
              </div>
              {list.length > 0 ? (
                <>
                  <div className="user-row head">
                    <div className="info">Name</div>
                    <div className="info">KYC STATUS</div>
                    <div className="info">Phone</div>
                    <div className="info">Role</div>
                    <div className="info">Sponsor</div>
                    <div className="info">Private Sale 1</div>
                    <div className="info">Private Sale 2</div>
                    <div className="info">Email</div>
                    <div className="info">Created At</div>
                    {profileData.role === "SuperAdmin" && (
                      <div className="info">Action</div>
                    )}
                  </div>
                  {list.map((listEl, index) => (
                    <div className="user-row" key={index} ref={anim}>
                      <div className="info" data-responsive="Name">
                        {listEl.name}
                      </div>
                      <div className="info" data-responsive="KYC STATUS">
                        {listEl.kycStatus}
                      </div>
                      <div className="info" data-responsive="Phone">
                        {listEl.phone}
                      </div>
                      <div className="info" data-responsive="Role">
                        {listEl.role}
                      </div>
                      <div className="info" data-responsive="Sponsor">
                        {listEl.sponsor === "" ? "-" : listEl.sponsor}
                      </div>
                      <div className="info" data-responsive="Private Sale 1">
                        {listEl.privateSale1 === null
                          ? "-"
                          : listEl.privateSale1}
                      </div>
                      <div className="info" data-responsive="Private Sale 2">
                        {listEl.privateSale2 === null
                          ? "-"
                          : listEl.privateSale2}
                      </div>
                      <div className="info" data-responsive="Email">
                        <div style={{ fontSize: "10px" }}> {listEl.email}</div>
                      </div>
                      <div className="info" data-responsive="Created At">
                        {moment(listEl.createdAt).format("D MMM YY")}
                      </div>
                      {profileData.role === "SuperAdmin" && (
                        <div
                          className="info button-action"
                          data-responsive="Action"
                          onClick={() => {
                            dispatch(setUser(listEl));
                            setIsOpen(true);
                          }}
                        >
                          ...
                        </div>
                      )}
                    </div>
                  ))}
                  {loadMore && (
                    <div
                      onClick={() => {
                        console.log(latencySearch);
                        if (
                          latencySearch === "" ||
                          latencySearch === undefined
                        ) {
                          dispatch(
                            getUserList({
                              page: page + 1,
                              pageSize: 5,
                              search: "",
                            })
                          )
                            .then(unwrapResult)
                            .then((result) => {
                              if (result.length === 0) {
                                showLoadMore(false);
                              } else {
                                showLoadMore(true);
                              }
                              setList(result);
                              setList(list.concat(result));
                              setSearchPage(1);
                              setPage(page + 1);
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        } else {
                          dispatch(
                            getUserList({
                              page: searchPage + 1,
                              pageSize: 5,
                              search: latencySearch,
                            })
                          )
                            .then(unwrapResult)
                            .then((result) => {
                              if (result.length === 0) {
                                showLoadMore(false);
                              } else {
                                showLoadMore(true);
                              }
                              setList(result);
                              setList(list.concat(result));
                              setSearchPage(searchPage + 1);
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }
                      }}
                      className="load-more"
                    >
                      LOAD MORE
                    </div>
                  )}
                </>
              ) : (
                <p>No User!</p>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default UserList;
